import {defineMessages} from 'react-intl';

const messages = defineMessages({
  sort_by_menu_label: {
    id: 'sort_by_menu_label',
    defaultMessage: 'sort by'
  },
  guideTitle: {
    id: 'guideTitle',
    defaultMessage: 'Guide title'
  },
  lastPublished: {
    id: 'lastPublished',
    defaultMessage: 'Last published'
  }
});

export default messages;
