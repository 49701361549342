import styled from 'styled-components';

export const InstructionOverviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const InstructionOverviewHeaderWrapper = styled.div``;
export const InstructionOverviewContentWrapper = styled.div`
  flex: 1;
  position: relative;
`;
