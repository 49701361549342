import React from 'react';
import {inject, observer} from 'mobx-react';

//components
import EditGuideLink from 'components/EditGuideLink';

//styles
import {GuideView} from './styles';

const GuideViewComponent = ({SectionComponent, store}) => {
  const {router} = store;

  if (!SectionComponent) {
    return null;
  }

  return (
    <GuideView>
      <EditGuideLink />
      <SectionComponent {...router.params} />
    </GuideView>
  );
};

export default inject('store')(observer(GuideViewComponent));
