import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import map from 'lodash/map';
import {toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import {normalizeSimpleSearchResult} from 'shared/utils/search-result-utils';
import {SHOULD_SHOW_INSTRUCTIONS_ON_SIMPLE_SEARCH} from 'shared/constants';

//queries
import {SimpleSearch} from 'api/simple-search/queries';
import {simpleSearchOptions} from 'api/simple-search/query-options';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';
import TagPicker from 'shared/components/TagPicker';
import BaseSearchResultList from './BaseSearchResultList';

//messages
import messages from './messages';

const SimpleSearchResultList = flowRight(
  inject('store'),
  graphql(SimpleSearch, simpleSearchOptions),
  observer
)(({searchTerm, selectedTags, simpleSearch, store}) => {
  const {explorerPage} = store;
  const {allTags, isTagPickerDialogOpen, setIsTagPickerDialogOpen, setSelectedTagsAndRefresh} = explorerPage;

  const guides = map(get(simpleSearch, 'simpleSearch.results.guides'), guide =>
    normalizeSimpleSearchResult(guide, 'guides')
  );

  const instructions = SHOULD_SHOW_INSTRUCTIONS_ON_SIMPLE_SEARCH
    ? map(get(simpleSearch, 'simpleSearch.results.instructions'), instruction =>
        normalizeSimpleSearchResult(instruction, 'instructions')
      )
    : [];

  return (
    <BaseSearchResultList
      guideResults={guides}
      instructionResults={instructions}
      introSectionContent={
        <TagPicker
          allTags={allTags}
          isDialogOpen={isTagPickerDialogOpen}
          onChange={setSelectedTagsAndRefresh}
          onDialogClose={() => setIsTagPickerDialogOpen(false)}
          onDialogOpen={() => setIsTagPickerDialogOpen(true)}
          value={toJS(selectedTags)}
        />
      }
      noResultsSuggestionContent={<FormattedMessage {...messages.pageTitle_searchAnotherTerm} />}
      searchTerm={searchTerm}
    />
  );
});

SimpleSearchResultList.propTypes = {
  searchTerm: PropTypes.string.isRequired
};

export default SimpleSearchResultList;
