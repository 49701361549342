import styled from 'styled-components';
import {breakpointMobileOnly} from 'styles/vars';

export const MaxWidthContainer = styled.div`
  max-width: 1280px;
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;

  ${breakpointMobileOnly(`
    padding: 0 1rem;
  `)}
`;
