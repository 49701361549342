import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;
`;
export const SubmenuItem = styled.button`
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  cursor: pointer;
  padding-left: ${p => `${p.marginIndex + 1 * 1}rem`};

  label {
    opacity: 0.7;
  }
  &:hover label,
  &:focus label {
    opacity: 1;
    color: #000000;
  }
  &:focus {
    outline: none;
  }

  &.selected {
    pointer-events: none;
    cursor: default;
    opacity: 1;
    color: #000000;
  }

  .icon {
    color: ${props => props.theme.submenuTickColor};
  }
`;
