export const searchOptions = {
  name: 'search',
  options: ({store, searchTerm, size}) => {
    const {platform, explorerPage} = store;
    const {allSelectedTags} = explorerPage;
    return {
      notifyOnNetworkStatusChange: true,
      variables: {searchTerm, locale: platform.getLanguage(), tags: allSelectedTags, size}
    };
  },
  skip: ({skipSearch}) => skipSearch
};
