import React, {Component} from 'react';

//styled-components
import {OverlayMenu, Content} from './styles';

class OverlayMenuComponent extends Component {
  render() {
    const {children, alignment = 'right'} = this.props;
    return (
      <OverlayMenu alignment={alignment}>
        <Content>{children}</Content>
      </OverlayMenu>
    );
  }
}

export default OverlayMenuComponent;
