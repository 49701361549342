import styled from 'styled-components';
import $Link from 'shared/components/Link';
import $Tag from 'shared/components/Tag';

export const ResultsList = styled.div`
  margin-bottom: 64px;
`;

export const ResultsListWrapper = styled.div`
  margin-bottom: 32px;
`;

export const ResultCardWrapper = styled.div``;

export const NoResultsMessage = styled.div`
  display: flex;
`;

export const Link = styled($Link)`
  color: ${p => p.theme.buttonColor};
  font-weight: 900;
  margin-right: 6px;
  cursor: pointer;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IntroWrapper = styled.div`
  margin-left: -16px;
`;

export const Tag = styled($Tag)`
  margin-left: 16px;
`;
