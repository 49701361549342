import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

//helpers
import views from 'config/views';
import {NormalizedSearchResultPropType} from 'shared/utils/search-result-utils';

//components
import MaxWidthContainer from 'components/Layout/MaxWidthContainer';
import PageTitle from 'components/Layout/PageTitle';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import {HeadingDesktop as Heading} from 'shared/components/Heading';
import {ResultRowDesktop as ResultRow} from 'shared/components/ResultRow';
import {TextDesktop as Text} from 'shared/components/Text';

//styled-components
import {
  DetailsWrapper,
  IntroWrapper,
  Link,
  NoResultsMessage,
  ResultCardWrapper,
  ResultsList,
  ResultsListWrapper
} from './styles';

//messages
import messages from './messages';

const getPageTitle = ({guidesLength, searchTerm}) => {
  if (guidesLength < 1) {
    return searchTerm ? (
      <FormattedMessage {...messages.pageTitle_noSearchResultsFor} values={{searchString: searchTerm}} />
    ) : (
      <FormattedMessage {...messages.pageTitle_noSearchResults} />
    );
  }

  return searchTerm ? (
    <FormattedMessage {...messages.pageTitle_searchResultsFor} values={{searchString: searchTerm}} />
  ) : (
    <FormattedMessage {...messages.pageTitle_searchResults} />
  );
};

@inject('store')
@observer
class BaseSearchResultListComponent extends Component {
  static propTypes = {
    genericResults: PropTypes.arrayOf(NormalizedSearchResultPropType),
    guideResults: PropTypes.arrayOf(NormalizedSearchResultPropType),
    instructionResults: PropTypes.arrayOf(NormalizedSearchResultPropType),
    introSectionContent: PropTypes.node,
    noResultsSuggestionContent: PropTypes.node,
    searchTerm: PropTypes.string.isRequired
  };

  render() {
    const {
      genericResults,
      guideResults,
      instructionResults,
      introSectionContent,
      noResultsSuggestionContent,
      searchTerm,
      store
    } = this.props;

    const resultCount = size(genericResults) + size(guideResults) + size(instructionResults);

    return (
      <ResultsList>
        <MaxWidthContainer>
          <PageTitle>
            <Heading ellipsis size="large" tag="h2">
              {getPageTitle({guidesLength: resultCount, searchTerm})}
            </Heading>
            <DetailsWrapper>
              {resultCount > 0 ? (
                <Text secondary>
                  <FormattedMessage {...messages.x_results} values={{resultCount: resultCount}} />
                </Text>
              ) : (
                <NoResultsMessage>
                  <Link route={views.explorer} store={store}>
                    <FormattedMessage {...messages.pageTitle_backToHome} />
                  </Link>
                  {noResultsSuggestionContent && <Text secondary>{noResultsSuggestionContent}</Text>}
                </NoResultsMessage>
              )}
            </DetailsWrapper>
            {introSectionContent && <IntroWrapper>{introSectionContent}</IntroWrapper>}
          </PageTitle>
          {!isEmpty(guideResults) && (
            <ResultsListWrapper>
              <Heading size="normal" tag="h3">
                <FormattedMessage {...messages.searchPage_guides} />
              </Heading>
              {guideResults.map(result => (
                <ResultCardWrapper key={result.id}>
                  <ResultRow result={result} shouldShowShadowOnHover />
                </ResultCardWrapper>
              ))}
            </ResultsListWrapper>
          )}
          {!isEmpty(instructionResults) && (
            <ResultsListWrapper>
              <Heading size="normal" tag="h3">
                <FormattedMessage {...messages.searchPage_instructions} />
              </Heading>
              {instructionResults.map(result => (
                <ResultCardWrapper key={result.id}>
                  <ResultRow result={result} shouldShowShadowOnHover />
                </ResultCardWrapper>
              ))}
            </ResultsListWrapper>
          )}
          {!isEmpty(genericResults) && (
            <ResultsListWrapper>
              {genericResults.map(result => (
                <ResultCardWrapper key={result.id}>
                  <ResultRow result={result} shouldShowShadowOnHover />
                </ResultCardWrapper>
              ))}
            </ResultsListWrapper>
          )}
        </MaxWidthContainer>
      </ResultsList>
    );
  }
}

export default BaseSearchResultListComponent;
