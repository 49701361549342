import styled from 'styled-components';

//helpers
import {flexGrid} from 'styles/vars';

//components
import $InfiniteScroll from 'shared/components/InfiniteScroll';

export const GuidesList = styled.div``;

// EXAMPLE usecase
export const GuidesListWrapper = styled.div`
  ${flexGrid(4, 2, '(min-width: 800px)', 8)}
  ${flexGrid(3, 2, '(min-width: 600px) and (max-width: 799px)', 4)}
  ${flexGrid(2, 1, '(min-width: 320px) and (max-width: 599px)', 2)}
  ${flexGrid(2, 0.5, '(max-width: 319px)', 1)}
`;

export const GuideCardWrapper = styled.div``;

export const StyledInfiniteScroll = styled($InfiniteScroll)`
  margin-top: 32px;
  max-width: none;
  width: 100%;
`;
