import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import {ClickableArea, InstructionsList, TopicTitle} from './styles';

//components
import InstructionRow from 'components/InstructionRow';
import {HeadingDesktop as Heading} from 'shared/components/Heading';
import {TextDesktop as Text} from 'shared/components/Text';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import Icon from 'shared/components/Icon';

@inject('store')
@observer
class InstructionsListComp extends Component {
  toggleCollapsed = () => {
    const {id} = this.props.topic;
    const {ui} = this.props.store;

    ui.toggleCollapsible(id);
  };

  get collapsed() {
    const {id} = this.props.topic;
    const {ui} = this.props.store;

    return ui.isCollapsed(id);
  }

  componentDidMount() {
    const {guidePage, ui} = this.props.store;
    const {collapsible, id, visible, slug} = this.props.topic;

    if (collapsible && visible) {
      const defaultValue = slug !== guidePage.lastVisitedTopicSlug;
      ui.initCollapsible(id, defaultValue);
    }
  }

  render() {
    const {title, instructions, slug, visible, collapsible} = this.props.topic;
    if (instructions.length < 1) {
      return null;
    }

    return (
      <InstructionsList collapsed={this.collapsed}>
        {visible && (
          <TopicTitle>
            <Heading tag={'h3'} size={'xsmall'}>
              {this.renderTopicTitle(title, collapsible)}
            </Heading>
          </TopicTitle>
        )}
        {instructions.length < 1 && (
          <Text secondary>
            <FormattedMessage
              id={'pageTitle_info_noInstructionsInTopic'}
              defaultMessage={'there are no instructions in this topic'}
            />
          </Text>
        )}
        {!this.collapsed &&
          instructions.map((instruction, key) => (
            <InstructionRow key={key} instruction={instruction} topicSlug={slug} />
          ))}
      </InstructionsList>
    );
  }

  renderTopicTitle(title, collapsible) {
    if (collapsible) {
      return (
        <ClickableArea onClick={this.toggleCollapsed}>
          <Icon name={this.collapsed ? 'chevron-right' : 'chevron-down'} size={25} valign="-7px" />
          <strong>{title}</strong>
        </ClickableArea>
      );
    }

    return <strong>{title}</strong>;
  }
}

export default InstructionsListComp;
