import {defineMessages} from 'react-intl';

const messages = defineMessages({
  message_youAreAlreadyLoggedIn: {
    id: 'message_youAreAlreadyLoggedIn',
    defaultMessage: "You're already logged in"
  },
  messages_byUsingOurService: {
    id: 'Login.byUsingOurService',
    defaultMessage: 'By using our service, you agree with our {termsAndPolicyLink}'
  },
  termsAndConditionsAndPrivacyPolicy: {
    id: 'Login.termsAndConditionsAndPrivacyPolicy',
    defaultMessage: 'Terms & Conditions and Privacy policy.'
  },
  message_cantViewContent: {
    id: 'Login.cantViewContent',
    defaultMessage:
      'You do not have access to this Workspace. Contact your Platform Admin to be added to this Workspace or contact Customer Support to find out who your Admin is.'
  },
  signInToYourWorkspace: {
    id: 'signInToYourWorkspace',
    defaultMessage: 'Sign in to your workspace'
  }
});

export default messages;
