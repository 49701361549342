export const getTopicOverviewOptions = {
  name: 'topic',
  options: ({store}) => {
    const {router, platform} = store;
    const {guideSlug, topicSlug} = router.params;
    return {
      variables: {
        slug: topicSlug,
        locale: platform.getLanguage(),
        guideSlug
      }
    };
  }
};
