class Step {
  html;
  number;

  constructor(props) {
    Object.keys(props).forEach(key => (this[`${key}`] = props[`${key}`]));

    const {position} = props;
    this.number = position + 1;
  }
}

export default Step;
