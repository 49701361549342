import gql from 'graphql-tag';

export const UserInfo = gql`
  query userInfoForDesktop {
    me {
      id
      fullName
      email
      canViewContent
      isSsoLogin
      organization {
        id
        name
        theme {
          id
        }
      }
    }
  }
`;
