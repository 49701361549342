import {observable, action, computed, makeObservable} from 'mobx';
import find from 'lodash/find';
import get from 'lodash/get';

import eventsMap from 'shared/analytics-event-mappings';
import {INSTRUCTION_TYPE} from 'shared/enums';

import store from 'stores/store';
import Step from 'stores/models/step';

class InstructionPage {
  @observable lastVisitedStep;
  currentInstruction;
  currentGuideId;
  stepIdToPositionMap;
  currentTopicId;
  @observable currentGuideVersionId;

  @action
  setCurrentGuideVersionId = currentGuideVersionId => (this.currentGuideVersionId = currentGuideVersionId);

  setCurrentInstruction = currentInstruction => {
    this.currentInstruction = currentInstruction;
  };

  setCurrentTopic = currentTopicId => {
    this.currentTopicId = currentTopicId;
  };

  setCurrentGuideId = guideId => {
    this.currentGuideId = guideId;
  };

  setStepIdToPositionMap = map => {
    this.stepIdToPositionMap = map && map.map(step => new Step(step));
  };

  trackInstructionView = () => {
    this.trackPageViewEvent({
      guideId: this.currentGuideId,
      instructionId: this.currentInstruction.id,
      topicId: this.currentTopicId
    });
  };

  trackInstructionLeave = eventAction => {
    const stepId = get(this.lastVisitedStep, 'id', null);
    const stepNo = get(this.lastVisitedStep, 'number', null);

    this.trackActionEvent(eventAction, {
      guideId: this.currentGuideId,
      instructionId: this.currentInstruction.id,
      topicId: this.currentTopicId,
      stepId,
      stepNo
    });
  };

  trackStepView = () => {
    const stepId = get(this.lastVisitedStep, 'id', null);
    const stepNo = get(this.lastVisitedStep, 'number', null);

    this.trackPageViewEvent({
      instructionId: this.currentInstruction.id,
      stepId,
      stepNo,
      guideId: this.currentGuideId,
      topicId: this.currentTopicId
    });
  };

  trackStepChange = eventAction => {
    const stepId = get(this.lastVisitedStep, 'id', null);
    const stepNo = get(this.lastVisitedStep, 'number', null);

    this.trackActionEvent(eventAction, {
      instructionId: this.currentInstruction.id,
      stepId,
      stepNo,
      guideId: this.currentGuideId,
      topicId: this.currentTopicId
    });
  };

  trackStepsOverviewView = () => {
    this.trackPageViewEvent({
      instructionId: this.currentInstruction.id,
      guideId: this.currentGuideId,
      topicId: this.currentTopicId
    });
  };

  trackStepsOverviewLeave = () => {
    this.trackActionEvent('leaveStepsOverview', {
      guideId: this.currentGuideId,
      stepOverview: 1,
      instructionId: this.currentInstruction.id,
      topicId: this.currentTopicId
    });
  };

  trackHintView = hintType => {
    const {analytics} = store;
    const stepId = get(this.lastVisitedStep, 'id', null);
    const stepNo = get(this.lastVisitedStep, 'number', null);

    analytics.trackActionEvent({
      ...eventsMap['openHint'],
      actionContent: hintType,
      objectsInfo: {
        guideId: this.currentGuideId,
        instructionId: this.currentInstruction.id,
        topicId: this.currentTopicId,
        stepId,
        stepNo
      }
    });
  };

  trackHintClose = (eventAction, hintName) => {
    const stepId = get(this.lastVisitedStep, 'id', null);
    const stepNo = get(this.lastVisitedStep, 'number', null);

    this.trackActionEvent(eventAction, {
      guideId: this.currentGuideId,
      instructionId: this.currentInstruction.id,
      topicId: this.currentTopicId,
      stepId,
      stepNo,
      hintName
    });
  };

  trackActionEvent = (eventAction, objectsInfo) => {
    const {analytics} = store;
    analytics.trackActionEvent({
      ...eventsMap[eventAction],
      objectsInfo
    });
  };

  trackPageViewEvent = objectsInfo => {
    const {analytics} = store;
    analytics.trackPageViewEvent({
      objectsInfo
    });
  };

  @action setLastVisitedStep = (position, totalStepsNumber) => {
    if (!this.currentInstruction) return;

    const campaignCardExists = totalStepsNumber - this.currentInstruction.stepsNumber > 2;

    if (position == 0) {
      this.lastVisitedStep = {id: 'cover', number: position};
    } else if (position > this.currentInstruction.stepsNumber) {
      const stepId =
        campaignCardExists && position == this.currentInstruction.stepsNumber + 1 ? 'campaign' : 'nextInstruction';
      this.lastVisitedStep = {id: stepId, number: position};
    } else {
      this.lastVisitedStep = find(this.stepIdToPositionMap, step => {
        return step.number == position;
      });
    }
  };

  constructor() {
    makeObservable(this);
  }

  @computed get activeStepNumber() {
    return this.lastVisitedStep ? Number(this.lastVisitedStep.number) : 0;
  }

  @computed get activeStepId() {
    return this.lastVisitedStep ? this.lastVisitedStep.id : null;
  }

  @computed get isChecklist() {
    return this.currentInstruction && this.currentInstruction.type === INSTRUCTION_TYPE.CHECKLIST;
  }
}

export default InstructionPage;
