import filter from 'lodash/filter';
import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import {normalizeElasticSearchResult} from 'shared/utils/search-result-utils';

//queries
import {Search} from 'api/search/queries';
import {searchOptions} from 'api/search/query-options';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';
import BaseSearchResultList from './BaseSearchResultList';

//styled-components
import {Tag} from './styles';

//messages
import messages from './messages';

const ElasticSearchResultList = flowRight(
  inject('store'),
  graphql(Search, searchOptions),
  observer
)(({search, searchTerm, selectedTags, store}) => {
  const {explorerPage} = store;
  const {removeSelectedTag} = explorerPage;

  const results = filter(get(search, 'search.results'), result => !result._index.includes('tags'));
  const normalizedResults = results.map(normalizeElasticSearchResult);

  return (
    <BaseSearchResultList
      genericResults={normalizedResults}
      introSectionContent={selectedTags.map(tagName => (
        <Tag
          key={tagName}
          iconName="close"
          onIconClick={() => removeSelectedTag(tagName)}
          iconSize={8}
          tagName={tagName}
        />
      ))}
      noResultsSuggestionContent={
        selectedTags.length ? (
          <FormattedMessage {...messages.pageTitle_removeFilter} />
        ) : (
          <FormattedMessage {...messages.pageTitle_searchAnotherTerm} />
        )
      }
      searchTerm={searchTerm}
    />
  );
});

ElasticSearchResultList.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default ElasticSearchResultList;
