export const getGuidesListOptions = {
  name: 'guides',
  options: ({store}) => {
    const {platform, explorerPage} = store;
    return {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: 10,
        locale: platform.getLanguage(),
        sortBy: explorerPage.sortBy
      }
    };
  }
};

export const getGuideOverviewOptions = {
  name: 'guide',
  options: ({store}) => {
    const {platform, router} = store;
    const {guideSlug} = router.params;
    return {
      variables: {
        slug: guideSlug,
        locale: platform.getLanguage()
      }
    };
  }
};

export const getGuideTitleOptions = {
  name: 'guideTitleQuery',
  options: ({store}) => {
    const {platform, router} = store;
    const {guideSlug} = router.params;
    return {
      variables: {
        slug: guideSlug,
        locale: platform.getLanguage()
      }
    };
  }
};

export const getGuideStatusOptions = {
  name: 'guideStatusQuery',
  options: ({store}) => {
    const {platform, router} = store;
    const {guideSlug} = router.params;
    return {
      variables: {
        slug: guideSlug,
        locale: platform.getLanguage()
      }
    };
  }
};
