import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {toJS} from 'mobx';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {isEmpty} from 'lodash';
import {ThemeProvider} from 'styled-components';
import NotificationSystem from 'react-notification-system';

import NotFoundComponent from 'components/NotFoundComponent';
import Header from 'components/Header';
import StickyHeader from 'components/StickyHeader';
import ErrorBoundary from 'components/ErrorBoundary';
import ScrollRestoration from 'shared/components/ScrollRestoration';
import ToastNotifications from 'shared/components/ToastNotifications';
import CookieBanner from 'shared/components/CookieBanner';
import LogoutDialog from 'shared/components/LogoutDialog';

import {Platform} from 'shared/api/platform/queries';
import {Tags} from 'api/tag/queries';
import {tagsOptions} from 'api/tag/query-options';

import views from 'config/views';
import {SidePanelProvider} from 'shared/components/SidePanel';

@inject('store')
@graphql(Platform)
@graphql(Tags, tagsOptions)
@observer
class App extends Component {
  componentWillMount() {
    const {
      store: {auth, platform}
    } = this.props;
    Promise.all([
      auth.getCurrentUser(),
      platform.fetchAvailableLanguages(),
      platform.fetchPlatformFlags(),
      platform.fetchDevelopmentFeatureFlags()
    ]);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.platform) {
      const {
        store: {app, platform},
        data
      } = nextProps;
      const {organization, domain} = data.platform;
      platform.setOrganization(organization);
      platform.setPlatformName(data.platform.name);
      platform.setAssetsFolder(data.platform.assetsFolder);
      platform.setDefaultDomainSlug(data.platform.defaultDomain);
      platform.setPlatformSlug(data.platform.subdomain);
      platform.setXmRealityTeamForDomain(domain);
      platform.setDomain(domain);
      platform.setIconsShowHintLabel(data.platform.showStepNoteIconLabel);
      app.setTheme({...domain.theme});
    }

    if (nextProps.tags && nextProps.tags.tags) {
      const {
        store: {explorerPage},
        tags: {tags}
      } = nextProps;
      explorerPage.setAllTags(tags);
    }
  }

  componentDidMount = () => {
    const {
      store: {
        platform: {addOnlineStatusListener},
        app: {setViews}
      }
    } = this.props;

    addOnlineStatusListener();
    setViews(views);
  };

  render() {
    const {store, data} = this.props;
    const {loading: dataLoading} = data;
    const {router, auth, app, platform, notification, cookiePreference} = store;
    const {getCurrentComponent, theme, showLogoutDialog} = app;
    const {setNotificationRef} = notification;
    const {
      acceptAllCookies,
      openCookiePreferencesDialog,
      setCookiePreferenceOption,
      cookiePreference: cookiePreferenceOption,
      hideCookiePreferencesDialog
    } = cookiePreference;
    const {activeLocale, localeMessages} = store.platform;

    if (!router.currentRoute || platform.notFound) {
      return <NotFoundComponent noIntl />;
    }

    const currentComponent = getCurrentComponent(store);
    const loading =
      !platform.loaded ||
      auth.authing ||
      dataLoading ||
      this.theme === null ||
      !platform.availableLanguages ||
      isEmpty(localeMessages);

    const header = props => <Header stick={props.stick} inInstruction={props.isInInstruction} />;

    if (loading) {
      return null;
    }
    return (
      <IntlProvider locale={activeLocale} messages={localeMessages[activeLocale]}>
        <ErrorBoundary>
          <SidePanelProvider>
            {theme.fontStylesheet && <style>{theme.fontStylesheet}</style>}
            <ThemeProvider theme={toJS(theme)}>
              <StickyHeader loading={loading} header={header}>
                <ScrollRestoration />
                {currentComponent}
              </StickyHeader>
            </ThemeProvider>
            <NotificationSystem ref={ref => setNotificationRef(ref)} />
            <ToastNotifications isDesktop />
            <CookieBanner
              openDialog={openCookiePreferencesDialog}
              acceptAllCookies={acceptAllCookies}
              cookiePreference={cookiePreferenceOption}
              setCookiePreferenceOption={setCookiePreferenceOption}
              goToPrivacyPage={() => {
                hideCookiePreferencesDialog();
                router.goTo(views.privacy, {});
              }}
            />
            <LogoutDialog isDesktop isOpen={showLogoutDialog} logout={auth.logout} />
          </SidePanelProvider>
        </ErrorBoundary>
      </IntlProvider>
    );
  }
}

export default App;
