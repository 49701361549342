export const simpleSearchOptions = {
  name: 'simpleSearch',
  options: ({searchTerm, store}) => {
    const {explorerPage, platform} = store;
    const {selectedTags, allTags} = explorerPage;

    /* Convert tag titles to tag ids */
    const tags = selectedTags
      .map(title => allTags.find(tag => tag.title === title))
      .filter(Boolean)
      .map(tag => tag.id);

    return {
      notifyOnNetworkStatusChange: true,
      variables: {
        locale: platform.getLanguage(),
        searchTerm,
        tags
      }
    };
  },
  skip: ({skipSearch}) => skipSearch
};
