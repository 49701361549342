import styled from 'styled-components';

import $StepCard from 'components/StepCard';

import {commonPalette} from 'shared/styles/palette';

export const SwipeableSteps = styled.div.attrs({
  className: 'swiper-container'
})`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SlideWrapper = styled.div.attrs({
  className: 'swiper-wrapper'
})`
  display: flex;
  box-sizing: content-box;
`;

export const SlideContainer = styled.div.attrs({
  className: 'swiper-slide'
})`
  position: relative;
  border-radius: 16px;

  > * {
    transition: all 0.5s;
    opacity: 0.3;
  }
  &.swiper-slide-active {
    > * {
      opacity: 1;
    }
  }
`;

export const StepCard = styled($StepCard)``;

export const ProgressBar = styled.div.attrs({
  className: 'swiper-pagination'
})`
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 100%;
  height: 0.25rem;
  left: 0;
  bottom: 0;

  .swiper-pagination-progressbar-fill {
    background: #cc0000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-origin: left top;
  }
`;

export const NavigationArrows = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px -2rem;
  align-items: center;
  button {
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.1;
  }
`;

const NavigationArrow = styled.button`
  background: none;
  border: none;
  padding: 2rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
export const NavigationStepXofX = styled.div`
  color: rgba(0, 0, 0, 0.5);
  strong {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const NavigationButtonPrevious = styled(NavigationArrow).attrs({
  className: 'swiper-button-prev',
  'data-cy': 'swiper-button-prev'
})`
  ${({disabled}) => (disabled ? `color: ${commonPalette.borderGrey}` : '')}
`;

export const NavigationButtonNext = styled(NavigationArrow).attrs({
  className: 'swiper-button-next',
  'data-cy': 'swiper-button-next'
})``;

const NavigationCardOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 16px;
`;

export const NavigationPrevCardOverlay = styled(NavigationCardOverlay).attrs({
  'data-cy': 'swiper-card-prev'
})`
  left: 0;
`;
export const NavigationNextCardOverlay = styled(NavigationCardOverlay).attrs({
  'data-cy': 'swiper-card-next'
})`
  right: 0;
`;
