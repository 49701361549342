import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import views from 'config/views';
import {graphql} from '@apollo/client/react/hoc/graphql';

//components
import InstructionsList from 'components/InstructionsList';
import MaxWidthContainer from 'components/Layout/MaxWidthContainer';
import NotFoundComponent from 'components/NotFoundComponent';
import RestoredScroll from 'shared/components/RestoredScroll';
import PageTitle from 'components/Layout/PageTitle';
import SpinnerMessage from 'components/Layout/SpinnerMessage';
import {HeadingDesktop as Heading} from 'shared/components/Heading';
import ContextMenu from 'components/Layout/PageTitle/ContextMenu';
import BackLink from 'components/Layout/PageTitle/BackLink';
import {FormattedMessage} from 'shared/components/FormattedComponents';

//queries
import {TopicOverview} from 'api/topics/queries';
import {GuideTitle} from 'api/guides/queries';
import {getTopicOverviewOptions} from 'api/topics/query-options';
import {getGuideTitleOptions} from 'api/guides/query-options';

//styles
import {TopicOverviewWrapper} from './styles';

import messages from './messages';

@inject('store')
@graphql(TopicOverview, getTopicOverviewOptions)
@graphql(GuideTitle, getGuideTitleOptions)
@observer
class TopicOverviewComponent extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      store: {app}
    } = nextProps;

    if (nextProps.guideTitleQuery && nextProps.guideTitleQuery.refetch) {
      app.addRefetchQuery({name: 'guideTitleQuery', query: nextProps.guideTitleQuery.refetch});
    }

    if (nextProps.topic && nextProps.topic.refetch) {
      app.addRefetchQuery({name: 'topic', query: nextProps.topic.refetch});
    }
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.removeRefetchQuery('topic');
    app.removeRefetchQuery('guideTitleQuery');
  }

  setBackByButton = () => {
    const {
      store: {guidePage}
    } = this.props;
    guidePage.setBackByButton(true);
  };

  render() {
    const {store, topic: topicQuery, guideTitleQuery, guideSlug} = this.props;
    const {error, loading, topic} = topicQuery;
    const {loading: guideLoading, error: guideError, guide} = guideTitleQuery;

    if (error || guideError) {
      return <h1>Failed to load topic. Please try again.</h1>;
    }

    if (loading || guideLoading) {
      return (
        <MaxWidthContainer>
          <PageTitle>
            <SpinnerMessage />
          </PageTitle>
        </MaxWidthContainer>
      );
    }

    if (!topic || !guide) {
      return <NotFoundComponent />;
    }

    const backLink = (
      <BackLink route={views.guide} store={store} onClickReaction={this.setBackByButton} params={{guideSlug}}>
        <FormattedMessage {...messages.pageTitle_action_backToCompleteGuide} />
      </BackLink>
    );

    const {title: guideTitle} = guide;

    return (
      <TopicOverviewWrapper>
        <RestoredScroll />
        <MaxWidthContainer>
          <PageTitle>
            <Heading>{guideTitle}</Heading>
            {backLink}
            <ContextMenu></ContextMenu>
          </PageTitle>
          <InstructionsList topic={topic} />
        </MaxWidthContainer>
      </TopicOverviewWrapper>
    );
  }
}

export default TopicOverviewComponent;
