import React, {Component} from 'react';
import {inject} from 'mobx-react';
import views from 'config/views';

import {Card, Content} from './styles';
import {HeadingDesktop as Heading} from 'shared/components/Heading';
import CloudinaryPictureWithControls from 'shared/components/CloudinaryPictureWithControls';
import Link from 'shared/components/Link';

@inject('store')
class RelatedInstructionCardComponent extends Component {
  render() {
    const {
      imageSizes,
      store,
      relatedInstruction: {slug, title, imageUrl, media},
      guideSlug,
      topicSlug,
      onClickReaction
    } = this.props;
    const params = {guideSlug, topicSlug, instructionSlug: slug};
    const metadata = media && media.metadata ? media.metadata : {};

    return (
      <Link store={store} route={views.instruction} params={params} onClickReaction={onClickReaction}>
        <Card>
          <CloudinaryPictureWithControls
            aspectRatio={10 / 4}
            metadata={metadata}
            src={imageUrl}
            sizes={imageSizes}
            shouldShowOverlayShapes
          />
          <Content>
            <Heading size={'xsmall'}>{title}</Heading>
          </Content>
        </Card>
      </Link>
    );
  }
}

export default RelatedInstructionCardComponent;
