import React, {Component} from 'react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';

//styled-components
import {FormTextareaWrapper, FormTextarea} from './styles';

@observer
class FormTextareaComponent extends Component {
  @observable content = '';
  @observable shadowContent = ' ';

  @action
  setContent = (content: string) => {
    this.content = content;
    this.shadowContent = content.replace(/\n$/, '\nx').replace(/\n/g, '\n');

    if (typeof this.props.onChange === 'function') {
      // testing sending event object shape instead of just the content.
      this.props.onChange({target: {value: content}});
    }
  };

  onChange = e => {
    this.setContent(e.target.value);
    if (this.props.onChange && typeof this.props.onChange === 'function') {
      this.props.onChange(e);
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    return (
      <FormTextareaWrapper>
        <pre>{this.shadowContent} </pre>
        <FormTextarea {...this.props} onChange={this.onChange} />
      </FormTextareaWrapper>
    );
  }
}

export default FormTextareaComponent;
