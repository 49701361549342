import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import {SENTRY_RELEASE} from 'shared/constants';

//raven
import Raven from 'raven-js';
Raven.config(process.env.SENTRY_DSN_VIEWERS || '', {
  release: SENTRY_RELEASE,
  captureUnhandledRejections: true
}).install();

//mobx
import store from 'stores/store';
import {Provider} from 'mobx-react';

//apollo
import {ApolloProvider} from '@apollo/client';
import {client} from 'utils/apollo-client';

//router
import {startRouter} from 'mobx-router';
import views from 'config/views';
import {normalizeCurrentUrl} from 'shared/utils/url-utils';

//components
import App from 'components/App';

import 'styles/index.css';
import 'shared/styles/css/lato.css';
import {GlobalStyles} from 'shared/theme-config';

import {browserDetect, navigationTracking} from 'shared/utils/generic-utils';

normalizeCurrentUrl();
startRouter(views, store, {
  // https://github.com/flatiron/director#configuration
  strict: false
});

if (browserDetect.isIE11) {
  document.documentElement.className += ' is-ie-11';
}

if (browserDetect.isEdge) {
  document.documentElement.className += ' is-edge';
}

// This will clear all existing awsalb cookies out there #2431
// TODO: remove this let's say in 2 months
document.cookie = 'AWSALB=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

const {redirect_referrer: redirectReferrer = ''} = queryString.parse(window.location.search);

navigationTracking.add(decodeURIComponent(redirectReferrer) || document.referrer);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
      <GlobalStyles />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
