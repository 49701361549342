import {defineMessages} from 'react-intl';

const messages = defineMessages({
  pageTitle_allGuides: {
    id: 'pageTitle_allGuides',
    defaultMessage: 'My Guides'
  },
  loading_failed_to_load_guide: {
    id: 'loading_failed_to_load_guide',
    defaultMessage: 'Failed to load {guideCount, plural, one {guide} other {guides}}. Please try again.'
  }
});

export default messages;
