import styled from 'styled-components';

export const ClickableArea = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: -12px;
  padding: 12px;
  > .icon {
    margin: -4px 0 -4px -8px;
  }
`;
export const InstructionsList = styled.div`
  display: block;
  margin-bottom: ${p => (p.collapsed ? 0 : '2rem')};
`;
export const TopicTitle = styled.div`
  padding: 1rem 0;
`;
