import {defineMessages} from 'react-intl';

const messages = defineMessages({
  stepXofX: {
    id: 'stepXofX',
    defaultMessage: 'Step {current} of {total}'
  },
  checkXofX: {
    id: 'checkXofX',
    defaultMessage: 'Check {current} of {total}'
  },
  pageTitle_action_backToCompleteGuide: {
    id: 'pageTitle_action_backToCompleteGuide',
    defaultMessage: 'back to complete guide'
  },
  title: {
    id: 'checklistSubmittedTitle',
    defaultMessage: 'Checklist Submitted'
  },
  subtitle: {
    id: 'checklistSubmittedSubtitle',
    defaultMessage: 'The results of your checklist have been submitted and are available in the Checklist report'
  },
  titleNotSubmitted: {
    id: 'titleNotSubmitted',
    defaultMessage: 'Checklist not submitted yet'
  },
  subtitleNotSubmitted: {
    id: 'subtitleNotSubmitted',
    defaultMessage: 'The results of your checklist have not yet been submitted'
  },
  campaignSignOff: {
    id: 'campaign_sign_off',
    defaultMessage: 'Sign-off'
  },
  sign_off_submitted_title: {
    id: 'sign_off_submitted',
    defaultMessage: 'Sign-off submitted'
  },
  sign_off_submitted_subtitle: {
    id: 'sign_off_submitted_subtitle',
    defaultMessage: 'Your answers for this sign-off have been submitted and are available in the Sign-off results.'
  },
  sign_off_not_submitted_title: {
    id: 'sign_off_not_submitted',
    defaultMessage: 'Sign-off not submitted yet'
  },
  sign_off_not_submitted_subtitle: {
    id: 'sign_off_not_submitted_subtitle',
    defaultMessage: 'Your answers for this sign-off has not been submitted.'
  }
});

export default messages;
