import {anonymousUserId} from 'shared/utils/data-utils';

export const getInstructionOverviewOptions = {
  name: 'instruction',
  options: ({store}) => {
    const {router, platform} = store;
    const {guideSlug, topicSlug, instructionSlug} = router.params;
    return {
      variables: {
        slug: instructionSlug,
        topicSlug: topicSlug,
        guideSlug: guideSlug,
        locale: platform.getLanguage(),
        anonymousUserId: anonymousUserId()
      }
    };
  }
};
