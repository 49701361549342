import styled from 'styled-components';
import {zIndex, breakpointMobileOnly} from 'styles/vars';

export const menuBarHeight = 48;

export const BarWrapper = styled.div`
  background: ${p => (p.theme.main ? p.theme.main : '#000000')};

  height: ${menuBarHeight}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: ${p => p.theme.menubarTextColor};
  line-height: 48px;
  padding: 0 1rem;
  z-index: ${zIndex.menuBarSticky};

  ${p => (p.stick ? 'position: fixed; top: 0;' : 'position: absolute;')};

  a {
    color: inherit;
    text-decoration: none;
  }

  ${breakpointMobileOnly(`
    padding: 0;
  `)};
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  display: ${p => (p.show ? 'block' : 'none')};
`;

export const StyledOption = styled.div`
  display: flex;
  margin-left: 10px;
`;
