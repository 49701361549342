import React, {Component} from 'react';

import Icon from 'shared/components/Icon';
import {TextDesktop as Text} from 'shared/components/Text';

//styled-components
import {BackLink} from './styles';

class BackLinkComponent extends Component {
  render() {
    const {children} = this.props;
    return (
      <BackLink {...this.props} shouldScrollBeRestored={true}>
        <Icon name={'arrow-left'} size={24} />
        <Text tag={'span'}>{children}</Text>
      </BackLink>
    );
  }
}

export default BackLinkComponent;
