import styled from 'styled-components';

export const ContextMenuLink = styled.button`
  display: block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  opacity: 0.5;
  min-width: 3rem;
  background: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;

  &:hover,
  &.active {
    color: inherit;
    opacity: ${p => (p.disabled ? 0.5 : 0.8)};
  }
  &:disabled {
    opacity: 0.1;
    pointer-events: none;
  }
  &:focus {
    outline: none;
  }
`;
