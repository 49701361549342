import styled from 'styled-components';
import {flexGrid} from 'styles/vars';

//components
import $StepOverviewCard from 'components/StepOverviewCard';

export const StepsOverview = styled.div`
  ${flexGrid(5, 2, '(min-width: 800px)', 2)}
  ${flexGrid(4, 1, '(max-width: 799px)', 1)}
`;

export const StepOverviewCardWrapper = styled.div``;

export const StepOverviewCard = styled($StepOverviewCard).attrs({
  imageSizes: `
    (min-width: 1280px) calc(1088px / 5),
    (min-width: 800px) calc((100vw - 192px) / 5),
    (max-width: 799px) calc((100vw - 80px) / 4)
  `
})``;
