import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const SignInView = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 20px 0;
  background-color: white;
`;

export const Caption = styled.h3`
  margin-bottom: 56px;
  font-weight: 600;
  color: ${commonPalette.txBlack};
  font-family: Lato;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 428px;
  height: 335px;
`;

export const TermsConditionsLabel = styled.div`
  color: #757575;
  font-size: 14px;
  line-height: 17px;
  margin-top: 60px;
  text-align: center;
  flex: 1;
`;

export const TermsConditionsLink = styled.a`
  margin-left: 0.2rem;
  transition: none;
  font-size: 14px;
  line-height: 24px;
  color: ${commonPalette.btnGreen};
  display: block;

  &:hover {
    color: ${commonPalette.btnGreen};
    text-decoration: underline;
  }
`;

export const CantViewContent = styled.div`
  color: red;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding-bottom: 20px;
`;
