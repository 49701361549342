import {Route} from 'mobx-router';

/**
 * @class RouteAdapter
 * @description Adapt the new mobx-router Route class to the one that was used before mobx-router upgrade.
 * @extends Route
 */
export class RouteAdapter extends Route {
  constructor({id, editor, actionMenu, ipa, localeEditor, restricted, ...options}) {
    super(options);
    this.id = id;
    this.editor = editor;
    this.actionMenu = actionMenu;
    this.ipa = ipa;
    this.localeEditor = localeEditor;
    this.restricted = restricted;
  }
}
