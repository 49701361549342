import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import views from 'config/views';

import {StepOverviewCardDesktop} from 'shared/components/StepOverviewCard';

@inject('store')
@observer
class StepOverviewCardComponent extends Component {
  render() {
    const {imageSizes, step, store} = this.props;
    const {checklist: checklistStore, instructionPage, router} = store;
    const {currentInstruction} = instructionPage;
    const {guideSlug, topicSlug, instructionSlug} = router.params;

    const params = {
      guideSlug,
      topicSlug,
      instructionSlug,
      stepId: step.number
    };

    return (
      <StepOverviewCardDesktop
        checkInfo={checklistStore.checklist[step.id]}
        instructionType={currentInstruction.type}
        imageSizes={imageSizes}
        step={step}
        store={store}
        route={views.step}
        params={params}
      />
    );
  }
}

export default StepOverviewCardComponent;
