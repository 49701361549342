import React, {Component} from 'react';
import {defineMessages} from 'react-intl';

import CenteredMessage from 'components/Layout/CenteredMessage';
import {FormattedMessage} from 'shared/components/FormattedComponents';

import {NotFound} from './styles';

const messages = defineMessages({
  error_pageNotFound: {
    id: 'error_pageNotFound',
    defaultMessage: 'Page not found'
  }
});

class NotFoundComponent extends Component {
  render() {
    const {noIntl} = this.props;
    return (
      <NotFound>
        <CenteredMessage icon={'warning'}>
          {!noIntl ? <FormattedMessage {...messages.error_pageNotFound} /> : 'Page not found'}
        </CenteredMessage>
      </NotFound>
    );
  }
}

export default NotFoundComponent;
