import styled from 'styled-components';

export const CenteredMessage = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0.7;
  .icon {
    display: block;
    margin: 0.5rem auto;
    opacity: 0.3;
  }
`;
