import {defineMessages} from 'react-intl';

const messages = defineMessages({
  x_results: {
    id: 'x_results',
    defaultMessage: '{resultCount, number} {resultCount, plural, one {result} other {results}}'
  },
  pageTitle_searchResults: {
    id: 'pageTitle_searchResults',
    defaultMessage: 'Search results'
  },
  pageTitle_searchResultsFor: {
    id: 'pageTitle_searchResultsFor',
    defaultMessage: 'Search results for "{searchString}"'
  },
  pageTitle_noSearchResults: {
    id: 'pageTitle_noSearchResults',
    defaultMessage: 'No search results'
  },
  pageTitle_noSearchResultsFor: {
    id: 'pageTitle_noSearchResultsFor',
    defaultMessage: 'No search results for "{searchString}"'
  },
  pageTitle_backToHome: {
    id: 'pageTitle_backToHome',
    defaultMessage: 'Back to home '
  },
  pageTitle_searchAnotherTerm: {
    id: 'pageTitle_searchAnotherTerm',
    defaultMessage: 'or try searching for another term'
  },
  pageTitle_removeFilter: {
    id: 'pageTitle_removeFilter',
    defaultMessage: 'or try removing your filters'
  },
  searchPage_guides: {
    id: 'searchPage_guides',
    defaultMessage: 'Guides'
  },
  searchPage_instructions: {
    id: 'searchPage_instructions',
    defaultMessage: 'Instructions'
  }
});

export default messages;
