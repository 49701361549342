import {defineMessages} from 'react-intl';

const messages = defineMessages({
  pageTitle_action_backToCompleteGuide: {
    id: 'pageTitle_action_backToCompleteGuide',
    defaultMessage: 'back to complete guide'
  }
});

export default messages;
