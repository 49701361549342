import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {observable, action, makeObservable} from 'mobx';

import SwipeableSteps from 'components/SwipeableSteps';
import StepsOverview from 'components/StepsOverview';
import MaxWidthContainer from 'components/Layout/MaxWidthContainer';
import SpinnerMessage from 'components/Layout/SpinnerMessage';
import ContextMenu from 'components/Layout/PageTitle/ContextMenu';
import ContextMenuLink from 'components/Layout/PageTitle/ContextMenuLink';
import BackLink from 'components/Layout/PageTitle/BackLink';
import PageTitle from 'components/Layout/PageTitle';
import Feedback from 'components/Feedback';
import NotFoundComponent from 'components/NotFoundComponent';

import {HeadingDesktop as Heading} from 'shared/components/Heading';
import RestoredScroll from 'shared/components/RestoredScroll';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import StepsSwiperResizeListener from 'shared/components/StepsSwiperResizeListener';

import {InstructionOverview} from 'api/instructions/queries';
import {GuideTitle} from 'api/guides/queries';
import {getInstructionOverviewOptions} from 'api/instructions/query-options';
import {getGuideTitleOptions} from 'api/guides/query-options';

import views from 'config/views';

import {INSTRUCTION_TYPE} from 'shared/enums';
import {INSTRUCTION_VIEWED} from 'shared/stores/campaign-store-constants';

import messages from './messages';
import {
  InstructionOverviewWrapper,
  InstructionOverviewHeaderWrapper,
  InstructionOverviewContentWrapper
} from './styles';

@inject('store')
@graphql(InstructionOverview, getInstructionOverviewOptions)
@graphql(GuideTitle, getGuideTitleOptions)
@observer
class InstructionOverviewComponent extends Component {
  @observable layout = {};

  @action
  handleLayoutChange = layout => {
    this.layout = layout;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentWillReceiveProps(nextProps) {
    const {guideTitleQuery, instruction} = nextProps;
    const {instruction: instructionData} = instruction;

    if (guideTitleQuery && guideTitleQuery.guide && guideTitleQuery.refetch && !guideTitleQuery.loading) {
      const {
        store: {app, instructionPage}
      } = nextProps;
      app.addRefetchQuery({name: 'guideTitleQuery', query: nextProps.guideTitleQuery.refetch});

      instructionPage.setCurrentGuideId(guideTitleQuery.guide.id);
      instructionPage.setCurrentGuideVersionId(guideTitleQuery.guide.publishedVersion._id);
    }

    if (instruction && instruction.refetch && !instruction.loading && instructionData && !guideTitleQuery.loading) {
      const {
        store: {router, app, campaign, instructionPage, checklist}
      } = nextProps;
      const {currentInstruction} = instructionPage;
      app.addRefetchQuery({name: 'instruction', query: nextProps.instruction.refetch});

      if (instruction.instruction.type === INSTRUCTION_TYPE.CHECKLIST) {
        checklist.setChecklistId(instruction.instruction.id);
      }

      instructionPage.setCurrentInstruction({
        id: instructionData.id,
        title: instructionData.title,
        stepsNumber: instructionData.stepsNumber,
        hasAlertRecipients: instructionData.hasAlertRecipients,
        type: instruction.instruction.type,
        steps: instruction.instruction.steps,
        checklistTemplate: instruction.instruction.checklistTemplate
      });

      instructionPage.setCurrentTopic(instructionData.topicId);

      if (!currentInstruction || currentInstruction.id !== instructionData.id) {
        const {id: instructionId, campaignTranslated} = instructionData;

        if (!router.params.stepId) {
          instructionPage.trackInstructionView();
        }

        campaign.triggerEvent({
          eventName: INSTRUCTION_VIEWED,
          campaign: campaignTranslated,
          instructionId
        });
      }

      instructionPage.setStepIdToPositionMap(
        instructionData.steps.map(step => {
          return {id: step.id, position: step.position};
        })
      );
    }
  }

  componentWillUnmount() {
    const {
      topicSlug,
      instructionSlug,
      store: {app, router, guidePage, instructionPage}
    } = this.props;
    const {instruction, loading} = this.props.instruction;
    app.removeRefetchQuery('instruction');
    app.removeRefetchQuery('guideTitleQuery');

    if (!loading && instruction && [views.guide.id, views.explorer.id].indexOf(router.currentRoute.id) !== -1) {
      const {backByButton, backByPlatformLink, backByLogo} = guidePage;
      let eventAction = 'backByReturnKey';
      if (backByButton) {
        eventAction = 'backByButton';
      } else if (backByPlatformLink) {
        eventAction = 'backByPlatformLink';
      } else if (backByLogo) {
        eventAction = 'backByLogo';
      }

      instructionPage.trackInstructionLeave(eventAction);

      guidePage.resetBackByButton();
      guidePage.resetBackByPlatformLink();
      guidePage.resetBackByLogo();
    }

    guidePage.lastVisitedInstructionSlug = instructionSlug;
    guidePage.lastVisitedTopicSlug = topicSlug;

    instructionPage.setCurrentInstruction();
    instructionPage.setStepIdToPositionMap();
    instructionPage.setLastVisitedStep();
  }

  setBackByButton = () => {
    const {
      store: {guidePage}
    } = this.props;
    guidePage.setBackByButton(true);
  };

  backFromStepsOverview = () => {
    const {
      store: {
        instructionPage: {trackStepsOverviewLeave}
      }
    } = this.props;
    trackStepsOverviewLeave();
  };

  render() {
    const {store, stepId, guideSlug, topicSlug, instructionSlug} = this.props;
    const {
      campaign,
      instructionPage,
      platform: {hasFeedbackEnabled, canUseXmReality, xMRealityTeamForDomain},
      checklist: {isChecklistSubmitted}
    } = store;
    const {loading, error, instruction} = this.props.instruction;
    const {loading: guideLoading, error: guideError, guide} = this.props.guideTitleQuery;
    const hasXmRealityEnabledForDomain = !!(canUseXmReality && xMRealityTeamForDomain);

    if (error || guideError) {
      return <h1>Failed to load instruction. Please try again.</h1>;
    }

    if (loading || guideLoading) {
      return (
        <MaxWidthContainer>
          <PageTitle>
            <SpinnerMessage />
          </PageTitle>
        </MaxWidthContainer>
      );
    }

    if (!instruction || !guide) {
      return <NotFoundComponent />;
    }

    const {title, steps, id, stepsNumber, imageUrl, nextInstruction, nextTopic, relatedInstructions, topicId, media} =
      instruction;

    const {title: guideTitle} = guide;
    const showSteps = stepsNumber !== 0;
    const showStepOverview = showSteps && stepId === 'overview';
    const showSwiper = showSteps && !showStepOverview;
    const goToGuideParams = {guideSlug};
    const goToOverviewParams = {guideSlug, topicSlug, instructionSlug, stepId: 'overview'};
    const {activeStepNumber, isChecklist} = instructionPage;
    const overviewBackView = activeStepNumber !== 0 ? views.step : views.instruction;
    const overviewBackParams =
      activeStepNumber !== 0
        ? {guideSlug, topicSlug, instructionSlug, stepId: activeStepNumber}
        : {guideSlug, topicSlug, instructionSlug};
    const metadata = media && media.metadata ? media.metadata : {};
    const typeXofX_message = isChecklist ? messages.x_checks : messages.x_steps;
    const titleMessage = isChecklist ? messages.pageTitle_checksOverview : messages.pageTitle_stepsOverview;

    return (
      <InstructionOverviewWrapper>
        <RestoredScroll />
        <InstructionOverviewHeaderWrapper>
          <MaxWidthContainer>
            <PageTitle>
              <Heading maxLines={1}>
                {showStepOverview ? <FormattedMessage {...titleMessage} values={{title}} /> : title}
              </Heading>
              {showStepOverview ? (
                <BackLink
                  route={overviewBackView}
                  store={store}
                  params={overviewBackParams}
                  onClickReaction={this.backFromStepsOverview}
                >
                  <FormattedMessage {...messages.pageTitle_action_back} />
                </BackLink>
              ) : (
                <BackLink
                  route={views.guide}
                  store={store}
                  params={goToGuideParams}
                  onClickReaction={this.setBackByButton}
                >
                  <FormattedMessage {...messages.pageTitle_action_backToCompleteGuide} />
                </BackLink>
              )}
              {!showStepOverview && !isChecklistSubmitted && (
                <ContextMenu>
                  <ContextMenuLink
                    route={views.step}
                    store={store}
                    params={goToOverviewParams}
                    iconName={'overview'}
                    data-cy="overview-link"
                  >
                    <FormattedMessage
                      {...typeXofX_message}
                      values={{stepCount: steps.length, checkCount: steps.length}}
                    />
                  </ContextMenuLink>
                  {hasFeedbackEnabled && <Feedback canUseXmReality={hasXmRealityEnabledForDomain} />}
                </ContextMenu>
              )}
            </PageTitle>
          </MaxWidthContainer>
        </InstructionOverviewHeaderWrapper>
        <InstructionOverviewContentWrapper>
          {showStepOverview ? (
            <StepsOverview steps={steps} />
          ) : (
            <StepsSwiperResizeListener
              defaultResponsiveMargin="large"
              maxCardWidth={1600}
              minHeight={200}
              minPortraitRatio={1.6}
              navigationArrowsHeight={96}
              onChange={this.handleLayoutChange}
              responsiveMargins={{
                small: {
                  breakpoint: 400,
                  cardHint: 8,
                  cardSpacing: 16
                },
                medium: {
                  breakpoint: 800,
                  cardHint: 8,
                  cardSpacing: 32
                },
                large: {
                  breakpoint: 1200,
                  cardHint: 40,
                  cardSpacing: 80
                }
              }}
            >
              {!showSteps && <FormattedMessage {...messages.pageTitle_info_noStepsInInstruction} />}
              {showSwiper && (
                <SwipeableSteps
                  layout={this.layout}
                  steps={steps}
                  stepsNumber={stepsNumber}
                  showCampaignQuestion={campaign.step !== 'none'}
                  campaign={campaign}
                  nextInstruction={nextInstruction}
                  nextTopic={nextTopic}
                  relatedInstructions={relatedInstructions}
                  instructionId={id}
                  imageUrl={imageUrl}
                  goToOverviewParams={goToOverviewParams}
                  guideTitle={guideTitle}
                  instructionTitle={title}
                  topicId={topicId}
                  metadata={metadata}
                />
              )}
            </StepsSwiperResizeListener>
          )}
        </InstructionOverviewContentWrapper>
      </InstructionOverviewWrapper>
    );
  }
}

export default InstructionOverviewComponent;
