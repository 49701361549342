import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import isEmpty from 'lodash/isEmpty';

//helpers
import {ElasticSearchResultList, SimpleSearchResultList} from 'components/SearchResultList';

@inject('store')
@observer
class SearchView extends Component {
  static propTypes = {
    isAdvanced: PropTypes.bool
  };

  componentDidMount() {
    const {store} = this.props;
    const {analytics, explorerPage} = store;
    const {searchTermFromURL, tagsFromURL, addSelectedTag, setSearchTerm} = explorerPage;

    if (searchTermFromURL) {
      setSearchTerm(searchTermFromURL);
    }

    if (tagsFromURL) {
      tagsFromURL.map(t => addSelectedTag(t));
    }

    analytics.trackPageViewEvent();
  }

  componentDidUpdate() {
    const {store} = this.props;
    const {
      router: {queryParams},
      explorerPage
    } = store;

    if (isEmpty(queryParams)) {
      explorerPage.goToExplorerPage();
    }
  }

  componentWillUnmount() {
    const {store} = this.props;
    const {explorerPage} = store;

    explorerPage.selectedTags = [];
  }

  render() {
    const {isAdvanced, store} = this.props;
    const {explorerPage} = store;

    const sharedProps = {
      searchTerm: explorerPage.searchTermFromURL,
      selectedTags: explorerPage.tagsFromURL
    };

    if (isAdvanced) {
      return <ElasticSearchResultList {...sharedProps} size={100} />;
    }

    return <SimpleSearchResultList {...sharedProps} />;
  }
}

export default SearchView;
