import gql from 'graphql-tag';

export const TopicOverview = gql`
  query topic($slug: String!, $locale: Locale!, $guideSlug: String!) {
    topic(slug: $slug, locale: $locale, guideSlug: $guideSlug) {
      id
      title
      slug
      guideId
      visible
      instructions {
        id
        title
        type
        slug
        stepsNumber
        imageUrl
        media {
          metadata
        }
      }
    }
  }
`;
