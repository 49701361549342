import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//queries
import {GuideStatus} from 'api/guides/queries';
import {getGuideStatusOptions} from 'api/guides/query-options';

//components
import {TextDesktop as Text} from 'shared/components/Text';

//styles
import {EditDraftGuide} from './styles';

@inject('store')
@graphql(GuideStatus, getGuideStatusOptions)
class EditGuideLink extends Component {
  render() {
    const {guideStatusQuery} = this.props;
    const {guide} = guideStatusQuery;

    const showEditDraftGuide = guide && guide.publishStatus && guide.publishStatus === 'DRAFT';
    const guideId = guide && guide.id;
    const editGuideLink = `/admin/guides/${guideId}`;

    if (showEditDraftGuide) {
      return (
        <EditDraftGuide href={editGuideLink}>
          <Text size={'small'}>This guide is in preview. Click here to edit</Text>
        </EditDraftGuide>
      );
    }

    return null;
  }
}

export default EditGuideLink;
