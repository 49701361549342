import React, {Component} from 'react';

//styled-components
import {PageTitle} from './styles';

class PageTitleComponent extends Component {
  render() {
    const {children} = this.props;
    return <PageTitle>{children}</PageTitle>;
  }
}

export default PageTitleComponent;
