import React, {Component} from 'react';
import {inject} from 'mobx-react';

//helpers
import views from 'config/views';

//components
import {GuideCardDesktop} from 'shared/components/GuideCard';

@inject('store')
class GuideCardComponent extends Component {
  render() {
    const {imageSizes, guide, store} = this.props;
    const {slug: guideSlug} = guide;

    const params = {guideSlug};

    return (
      <GuideCardDesktop
        guide={guide}
        store={store}
        route={views.guide}
        params={params}
        imageSizes={imageSizes}
        shouldShowAnimationWhileLoading={true}
      />
    );
  }
}

export default GuideCardComponent;
