import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import SubmenuItem from 'components/SubmenuItem';
import eventsMap from 'shared/analytics-event-mappings';
import {navigationTracking} from 'shared/utils/generic-utils';

@inject('store')
@observer
class LanguagePickerComponent extends Component {
  changeLanguage = (app, platform, language) => {
    platform.setLanguageWithoutReload(language);
    app.refetchData(language);
    this.trackLanguageChange(language);
  };

  trackLanguageChange = language => {
    const {
      store: {analytics}
    } = this.props;
    navigationTracking.add(document.location.toString(), true);
    analytics.trackActionEvent({
      ...eventsMap['changeLanguage'],
      actionContent: language
    });
  };

  render() {
    const {
      store: {platform, app}
    } = this.props;
    const {availableLanguages, currentLanguage} = platform;

    return (
      <div>
        {availableLanguages.map((language, index) => (
          <SubmenuItem
            onClick={() => this.changeLanguage(app, platform, language.locale)}
            key={index}
            selected={currentLanguage === language.locale}
          >
            {language.language}
          </SubmenuItem>
        ))}
      </div>
    );
  }
}

export default LanguagePickerComponent;
