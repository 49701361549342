import gql from 'graphql-tag';

export const InstructionOverview = gql`
  query instruction(
    $slug: String!
    $topicSlug: String!
    $guideSlug: String!
    $locale: Locale!
    $anonymousUserId: String
  ) {
    instruction(
      slug: $slug
      topicSlug: $topicSlug
      guideSlug: $guideSlug
      locale: $locale
      anonymousUserId: $anonymousUserId
    ) {
      id
      title
      imageUrl
      stepsNumber
      topicId
      type
      media {
        metadata
      }
      steps {
        id
        position
        imageUrl
        instructionHtml
        tipHtml
        tipMedia {
          url
          metadata
        }
        fixHtml
        fixMedia {
          url
          metadata
        }
        warningHtml
        warningMedia {
          url
          metadata
        }
        alternativeHtml
        alternativeMedia {
          url
          metadata
        }
        media {
          metadata
        }
        notes {
          iconId
          contentHtml
          mandatory
          media {
            url
            metadata
          }
        }
        disabledOptions {
          id
        }
      }
      nextInstruction {
        slug
        title
        imageUrl
        type
        media {
          metadata
        }
      }
      nextTopic {
        slug
        firstInstruction {
          type
          slug
          title
          imageUrl
          media {
            metadata
          }
        }
      }
      relatedInstructions {
        slug
        title
        imageUrl
        type
        media {
          metadata
        }
      }
      campaignTranslated {
        id
        lastDateOfAnswerForUser
        showPeriodSeconds
        question {
          followUps {
            kind
            question
            rangeFrom
            rangeTo
          }
          kind
          numOfRating
          question
          textTypeOptions
        }
        status
        title
      }
      hasAlertRecipients
      checklistTemplate {
        type
        id
        name
        options {
          id
          option
          value
        }
      }
    }
  }
`;
