import styled from 'styled-components';
import {breakpointMobileOnly} from 'styles/vars';

export const PageTitle = styled.div`
  margin: 2rem 0 2rem 0;
  position: relative;
  padding-right: 8rem;
  color: #000000;

  ${breakpointMobileOnly(`
    margin: 2rem 0;
  `)};

  @media (max-width: 321px) {
    margin: 1rem 0;
  }
`;
