import styled from 'styled-components';
import {borderRadius, cardShadowStyles} from 'styles/vars';

import {FormButtonDesktop as $FormButton} from 'shared/components/FormButton';

export const Card = styled.div`
  height: 100%;
  width: 100%;
  ${p => !p.hasOutro && 'background: #fff'};
  display: flex;
  border-radius: ${borderRadius.default}px;
  overflow: hidden;
  &.portrait {
    flex-direction: column;
  }
  ${p => !p.hasOutro && cardShadowStyles({hover: false})};
`;

export const ImageWrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  ${p =>
    p.hasOutro &&
    `
    ${cardShadowStyles({hover: false})};
    flex-shrink: 0;

    ${Card}.landscape > & {
      width: 50%;
    }

    ${Card}.portrait > & {
      height: 50%;
    }
  `}
`;

export const ContentWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;
export const Top = styled.div`
  flex: 1;
`;

export const RelatedInstructions = styled.div``;
export const RelatedInstructionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`;
export const RelatedInstruction = styled.div``;

export const NextInstructionLink = styled($FormButton).attrs({
  isLink: true
})``;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  color: #ffffff;
`;
