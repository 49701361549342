import React, {Component} from 'react';
import {inject} from 'mobx-react';
import inlineSVG from 'inline-svg';

@inject('store')
class PlatformIconAndNameComponent extends Component {
  componentDidMount() {
    const {
      app: {
        theme: {faviconUrl}
      }
    } = this.props.store;
    if (faviconUrl && faviconUrl.indexOf('.svg') > 0) {
      inlineSVG.init({
        svgSelector: 'img.inline-svg',
        initClass: 'js-inlinesvg'
      });
    }
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('js-inlinesvg');
  }

  render() {
    const {store, displayName} = this.props;
    const {
      app: {
        theme: {faviconUrl}
      }
    } = store;

    if (faviconUrl && faviconUrl.indexOf('.svg') > 0) {
      return <img className="inline-svg" data-src={faviconUrl} alt={displayName} />;
    } else if (faviconUrl && faviconUrl.indexOf('.ico') > 0) {
      return <img src={faviconUrl.replace('.ico', '.png')} alt={displayName} width={24} height={24} />;
    }
    return null;
  }
}

export default PlatformIconAndNameComponent;
