import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import isEmpty from 'lodash/isEmpty';

import StackSpace from 'components/Layout/StackSpace';

import CloudinaryPictureWithControls from 'shared/components/CloudinaryPictureWithControls';
import {HeadingDesktop as Heading} from 'shared/components/Heading';
import {TextDesktop as Text} from 'shared/components/Text';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import InstructionOutro from 'shared/components/InstructionOutro';
import FadingOutEffect from 'shared/components/FadingOutEffect';

import views from 'config/views';

import {INSTRUCTION_TYPE} from 'shared/enums';
import {getRelatedInstructionMessage} from 'shared/utils/related-instructions-utils.js';

import RelatedInstructionCard from './RelatedInstructionCard';

import messages from './messages';
import {
  Card,
  ImageWrapper,
  ContentWrapper,
  NextInstructionLink,
  RelatedInstructions,
  RelatedInstructionGrid,
  Top,
  ImageOverlay
} from './styles';

@inject('store')
@observer
class NextInstructionCardComponent extends Component {
  trackActionEvent = eventsMapKey => {
    const {
      instructionPage: {trackInstructionLeave}
    } = this.props.store;
    trackInstructionLeave(eventsMapKey);
  };

  trackNextInstructionChange = () => {
    this.trackActionEvent('nextInstruction');
  };

  trackRelatedInstructionChange = () => {
    this.trackActionEvent('relatedInstruction');
  };

  componentDidUpdate = () => {
    const {
      store: {
        checklist: {isChecklistSubmitted},
        campaign: {isSubmitted: isCampaignSubmitted}
      }
    } = this.props;

    if (isChecklistSubmitted || isCampaignSubmitted) {
      setTimeout(() => {
        if (this.animationDiv) {
          this.animationDiv.remove();
        }
      }, 3000);
    }
  };

  setBackByButton = () => {
    const {
      store: {guidePage}
    } = this.props;

    guidePage.setBackByButton(true);
  };

  render() {
    const {
      relatedImageSizes,
      imageSizes,
      store,
      nextInstruction,
      guideSlug,
      topicSlug,
      imageStyle,
      className,
      guideTitle,
      relatedInstructions,
      store: {
        checklist: {isChecklistSubmitted},
        instructionPage: {currentInstruction},
        campaign: {isSubmitted: isCampaignSubmitted}
      },
      intl: {formatMessage}
    } = this.props;
    const {slug: instructionSlug, title, imageUrl, media} = nextInstruction;
    const linkParams = {guideSlug, topicSlug, instructionSlug};
    const hasRelatedInstructions = !isEmpty(relatedInstructions);
    const metadata = media && media.metadata ? media.metadata : {};

    const isChecklist = currentInstruction.type === INSTRUCTION_TYPE.CHECKLIST;
    const isNextChecklist = nextInstruction.type === INSTRUCTION_TYPE.CHECKLIST;
    const goToNextMessage = isNextChecklist
      ? messages.action_goToThisNextChecklist
      : messages.action_goToThisNextInstruction;
    const imageWrapperStyle = isChecklist ? {flex: 1} : hasRelatedInstructions ? imageStyle : null;
    const relatedEntityMessage = getRelatedInstructionMessage(relatedInstructions || [], messages, isChecklist);
    const checklistInfoTitle = formatMessage(isChecklistSubmitted ? messages.title : messages.titleNotSubmitted);
    const checklistInfoSubtitle = formatMessage(
      isChecklistSubmitted ? messages.subtitle : messages.subtitleNotSubmitted
    );
    const hasOutro = isChecklistSubmitted || isCampaignSubmitted;

    return (
      <Card className={className} hasOutro={hasOutro}>
        {hasOutro && (
          <InstructionOutro
            isDesktop={true}
            title={isChecklist ? checklistInfoTitle : formatMessage(messages.sign_off_submitted_title)}
            subtitle={isChecklist ? checklistInfoSubtitle : formatMessage(messages.sign_off_submitted_subtitle)}
          />
        )}
        <ImageWrapper hasOutro={hasOutro} style={imageWrapperStyle}>
          {hasOutro && <FadingOutEffect innerRef={el => (this.animationDiv = el)} />}
          <CloudinaryPictureWithControls
            metadata={metadata}
            src={imageUrl}
            sizes={imageSizes}
            shouldShowOverlayShapes
          />
          <ImageOverlay>
            <Text secondary ellipsis>
              {guideTitle}
            </Text>
            <Heading ellipsis>{title}</Heading>
            <StackSpace />
            <NextInstructionLink
              onClickReaction={this.trackNextInstructionChange}
              store={store}
              route={views.instruction}
              params={linkParams}
            >
              <FormattedMessage {...goToNextMessage} />
            </NextInstructionLink>
          </ImageOverlay>
        </ImageWrapper>
        {hasRelatedInstructions && !hasOutro && (
          <ContentWrapper style={imageStyle}>
            <Top />
            <RelatedInstructions>
              <Text secondary>
                <FormattedMessage {...relatedEntityMessage} values={{count: relatedInstructions.length}} />
              </Text>
              <StackSpace size={0.5} />
              <RelatedInstructionGrid>
                {relatedInstructions.map((relatedInstruction, index) => (
                  <RelatedInstructionCard
                    imageSizes={relatedImageSizes}
                    key={index}
                    relatedInstruction={relatedInstruction}
                    guideSlug={guideSlug}
                    topicSlug={topicSlug}
                    onClickReaction={() => this.trackRelatedInstructionChange()}
                  />
                ))}
              </RelatedInstructionGrid>
            </RelatedInstructions>
          </ContentWrapper>
        )}
      </Card>
    );
  }
}

export default injectIntl(NextInstructionCardComponent);
