import React, {Component} from 'react';

//styled-components
import {ContextMenu} from './styles';

class ContextMenuComponent extends Component {
  render() {
    const {children} = this.props;
    return <ContextMenu>{children}</ContextMenu>;
  }
}

export default ContextMenuComponent;
