import gql from 'graphql-tag';

export const SimpleSearch = gql`
  query simpleSearch($locale: Locale!, $tags: [String], $searchTerm: String) {
    simpleSearch(locale: $locale, tags: $tags, searchTerm: $searchTerm) {
      results {
        guides {
          id
          locale
          media {
            metadata
            url
          }
          title
          totalPublishedInstructionsNumber
        }
        instructions {
          id
          locale
          media {
            metadata
            url
          }
          parentGuide {
            title
          }
          parentTopic {
            title
          }
          stepsNumber
          title
        }
      }
    }
  }
`;
