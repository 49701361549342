import flowRight from 'lodash/flowRight';
import {inject, observer} from 'mobx-react';
import React from 'react';

//components
import ElasticSearchBarDropdown from './ElasticSearchBarDropdown';
import SimpleSearchBarDropdown from './SimpleSearchBarDropdown';

/**
 * Wires drop-downs of different modes to the environment.
 */
const SearchBarDropdownComponent = flowRight(
  inject('store'),
  observer
)(({onModeToggleClick, searchTerm, store}) => {
  const {explorerPage} = store;

  const DropdownComponent = explorerPage.shouldUseSimpleSearchDropdownMode
    ? SimpleSearchBarDropdown
    : ElasticSearchBarDropdown;

  return (
    <DropdownComponent
      onModeToggleClick={onModeToggleClick}
      searchTerm={searchTerm}
      skipSearch={!explorerPage.canPerformSearch}
    />
  );
});

export default SearchBarDropdownComponent;
