export const swiperConfig = {
  speed: 400,
  slidesPerView: 'auto',
  centeredSlides: true,
  shortSwipes: true,
  touchEventsTarget: 'wrapper',
  threshold: 80,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  keyboard: {
    enabled: true,
    onlyInViewport: false
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'progressbar'
  }
};
