import flowRight from 'lodash/flowRight';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';

//helpers
import {normalizeElasticSearchResult} from 'shared/utils/search-result-utils';

//queries
import {Search} from 'api/search/queries';
import {searchOptions} from 'api/search/query-options';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';
import BaseSearchBarDropdown from './BaseSearchBarDropdown';

//messages
import messages from './messages';

//styles
import {TagWrapper, Tag} from './styles';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

/**
 * Fetches Elastic Search results from the API and feeds them to the
 * <BaseSearchBarDropdown /> component.
 */
const ElasticSearchBarDropdownComponent = flowRight(
  inject('store'),
  graphql(Search, searchOptions),
  observer
)(({onModeToggleClick, search, store, intl}) => {
  const {explorerPage} = store;
  const {addSelectedTag, selectedTags, setTranslations} = explorerPage;
  const {formatMessage} = intl;

  const [tagsResults, results] = partition(get(search, 'search.results'), result => result._index.includes('tags'));

  const normalizedResults = results.map(normalizeElasticSearchResult);
  const availableTags = tagsResults
    .map(tagResult => tagResult._source.title)
    .filter(tagTitle => !selectedTags.includes(tagTitle))
    .slice(0, 5);

  const hasError = !!get(search, 'error');

  const translations = mapValues(pick(messages, ['loading']), message => formatMessage(message));

  setTranslations(translations);

  return (
    <BaseSearchBarDropdown
      genericResults={normalizedResults}
      hasError={hasError}
      introSectionContent={
        !isEmpty(availableTags) && (
          <TagWrapper>
            {availableTags.map((tagName, index) => (
              <Tag
                key={`${tagName}-${index}`}
                iconName="plus"
                onClick={() => addSelectedTag(tagName)}
                tagName={tagName}
              />
            ))}
          </TagWrapper>
        )
      }
      introSectionTitle={<FormattedMessage {...messages.searchPage_tags} />}
      isLoading={get(search, messages.loading)}
      onModeToggleClick={onModeToggleClick}
    />
  );
});

ElasticSearchBarDropdownComponent.propTypes = {
  searchTerm: PropTypes.string,
  skipSearch: PropTypes.bool
};

export default injectIntl(ElasticSearchBarDropdownComponent);
