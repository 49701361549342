import {defineMessages} from 'react-intl';

const messages = defineMessages({
  feedback_menu_label: {
    id: 'feedback_menu_label',
    defaultMessage: 'feedback'
  },
  feedback_give_us_your_feedback: {
    id: 'feedback_give_us_your_feedback',
    defaultMessage: 'Give us your feedback.'
  },
  feedback_action_success: {
    id: 'feedback_action_success',
    defaultMessage: 'Feedback sent'
  },
  feedback_action_error: {
    id: 'feedback_action_error',
    defaultMessage: 'Feedback not sent! Please try again'
  },
  feedback_send_label: {
    id: 'feedback_send_label',
    defaultMessage: 'Send'
  },
  feedback_sending_label: {
    id: 'feedback_sending_label',
    defaultMessage: 'Sending'
  },
  feedback_xm_reality: {
    id: 'feedback_xm_reality',
    defaultMessage: 'XMReality'
  },
  // Needs copy from Chris
  errorAvailableCallers: {
    id: 'errorAvailableCallers',
    defaultMessage: 'An error has occurred'
  },
  noCallersAvailable: {
    id: 'noCallersAvailable',
    defaultMessage: 'There are no callers available'
  },
  createRever: {
    id: 'createRever',
    defaultMessage: 'Create continuous improvement task'
  }
});

export default messages;
