import React, {Component} from 'react';
import {action, observable, makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';

//components
import {StepCardDesktop} from 'shared/components/StepCard';
import StepCheckToolbar from 'shared/components/StepCheckToolbar';

@inject('store')
@observer
class StepCardComponent extends Component {
  @observable currentHintType = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {hintImageSizes, imageSizes, step, store} = this.props;
    const {router} = store;
    const {stepId} = router.params;

    const isCurrent = parseInt(stepId) === parseInt(step.position + 1);
    const instruction = store.instructionPage.currentInstruction;
    const isCheck = instruction.type === 'CHECKLIST';
    const checklistTemplate = instruction.checklistTemplate;
    const disabledOptionsIds = instruction.steps[step.position].disabledOptions?.map(option => option.id);
    const {hasChecklistsEnabled} = store.platform;

    const footer =
      isCheck && hasChecklistsEnabled ? (
        <StepCheckToolbar
          isDesktop={true}
          hasChecklistsEnabled={hasChecklistsEnabled}
          stepId={step.id}
          stepIndex={step.position}
          checklistTemplate={checklistTemplate}
          disabledOptionsIds={disabledOptionsIds}
        />
      ) : undefined;

    return (
      <StepCardDesktop
        autoplayVideo={isCurrent}
        isCurrent={isCurrent}
        hintImageSizes={hintImageSizes}
        imageSizes={imageSizes}
        onToggleHint={this.handleToggleHint}
        selectedHintType={this.currentHintType}
        noteContentKey={'contentHtml'}
        footer={footer}
        {...this.props}
      />
    );
  }

  @action
  handleToggleHint = ({type, trackAction}) => {
    const {store} = this.props;
    const {instructionPage: page} = store;

    const hasOpened = Boolean(type);

    this.currentHintType = type;

    if (hasOpened) {
      page.trackHintView(type);
    } else {
      page.trackHintClose(trackAction, type);
    }
  };
}

export default StepCardComponent;
