import React, {Component} from 'react';

import Icon from 'shared/components/Icon';

//styled-components
import {CenteredMessage} from './styles';

class CenteredMessageComponent extends Component {
  render() {
    const {children, icon} = this.props;

    return (
      <CenteredMessage>
        {icon && <Icon name={icon} size={32} />}
        {children}
      </CenteredMessage>
    );
  }
}

export default CenteredMessageComponent;
