import React, {Component} from 'react';
import GuidesList from '../../components/GuidesList';
import {inject} from 'mobx-react';

@inject('store')
class ExplorerViewComponent extends Component {
  componentDidMount() {
    const {
      store: {analytics}
    } = this.props;
    analytics.trackPageViewEvent();
  }

  render() {
    return <GuidesList />;
  }
}

export default ExplorerViewComponent;
