import styled from 'styled-components';
import Link from 'shared/components/Link';

export const BackLink = styled(Link)`
  display: inline-block;
  position: relative;
  padding-left: 2rem;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }

  .icon {
    position: absolute;
    left: 0;
  }
`;
