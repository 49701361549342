import styled from 'styled-components';

export const ContextMenu = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0;
  > * {
    margin-left: 1rem;
  }
`;
