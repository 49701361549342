import gql from 'graphql-tag';

export const Tags = gql`
  query tags {
    tags {
      id
      title
    }
  }
`;
