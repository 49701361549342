import * as vars from 'shared/styles/vars';

export const zIndex = {
  overlayMenu: 998,
  guidePreviewNotification: 999,
  menuBarSticky: 997,
  feedbackModal: 150
};

export const borderRadius = {
  default: 2
};

export const breakpoints = {
  mobile: 600
};

export const breakpointMobileOnly = style => {
  return `
      @media (max-width: 600px) {
        ${style};
      }
    `;
};

export const cardShadowStyles = (p = {hover: true}) => vars.cardShadowCss(p.hover);

export const flexGrid = (colCount, gridGap, mediaQuery, marginBottom) => {
  return `
    @media ${mediaQuery} {
      display: flex;
      flex-wrap: wrap;
      margin-left: ${(-1 * gridGap) / 2}rem;
      margin-right: ${(-1 * gridGap) / 2}rem;
      margin-bottom: ${marginBottom}rem;

      & > * {
        width: ${100 / colCount}%;
        max-width: ${100 / colCount}%;
        padding: ${gridGap / 2}rem;
        box-sizing: border-box;
      }
    }
  `;
};
