class NotificationStore {
  _position = 'br';
  notification;

  setNotificationRef = ref => (this.notification = ref);

  success = (message = '', title) => this.base({message, title, level: 'success'});

  error = (message = '', title) => this.base({message, title, level: 'error'});

  warning = (message = '', title) => this.base({message, title, level: 'warning'});

  info = (message = '', title) => this.base({message, title, level: 'info'});

  base = ({message = '', title, level = 'success'}) => {
    this.notification.addNotification({
      message,
      ...(title && {title}),
      level,
      position: this._position
    });
  };
}

export default NotificationStore;
