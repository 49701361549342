import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, action, makeObservable} from 'mobx';

//styled-components
import {MenuItemWrapper, MenuItemButton} from './styles';

@observer
class MenuItemComponent extends Component {
  @observable submenuOpen = false;
  @observable wrapperRef = null;

  @action
  toggleSubmenu = () => {
    this.submenuOpen = !this.submenuOpen;
  };

  @action
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.submenuOpen) {
      this.toggleSubmenu();
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }
  render() {
    const {children, content, type, active} = this.props;

    if (!content) {
      return (
        <MenuItemWrapper className={active ? 'active' : ''}>
          <MenuItemButton {...this.props}>{children}</MenuItemButton>
        </MenuItemWrapper>
      );
    }
    return (
      <MenuItemWrapper ref={this.setWrapperRef} type={type} className={this.submenuOpen ? 'active' : ''}>
        <MenuItemButton onClick={() => this.toggleSubmenu()}>{children}</MenuItemButton>
        <div onClick={() => this.toggleSubmenu()}>{this.submenuOpen && content}</div>
      </MenuItemWrapper>
    );
  }
}

export default MenuItemComponent;
