import React, {Component} from 'react';
import {inject} from 'mobx-react';
import views from '../../../config/views';

import MaxWidthContainer from 'components/Layout/MaxWidthContainer';

//styled-components
import {BrandingBarWrapper, StyledLink} from './styles';

@inject('store')
class BrandingBarComponent extends Component {
  backByLogo = () => {
    const {
      store: {guidePage}
    } = this.props;
    guidePage.setBackByLogo(true);
  };

  render() {
    const {store} = this.props;
    return (
      <BrandingBarWrapper>
        <MaxWidthContainer>
          <StyledLink route={views.explorer} store={store} onClickReaction={this.backByLogo}>
            {store.app.theme.logoUrl ? (
              <img
                src={store.app.theme.logoUrl}
                // TODO: add platform/brand name here
                alt={'SwipeGuide'}
              />
            ) : (
              <div />
            )}
          </StyledLink>
        </MaxWidthContainer>
      </BrandingBarWrapper>
    );
  }
}

export default BrandingBarComponent;
