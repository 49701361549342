import React from 'react';

import SignInOptionsPicker from 'shared/components/SignInOptionsPicker';
import SignInView from '../SignInView';
import views from 'config/views';

export default () => (
  <SignInView>
    <SignInOptionsPicker device="desktop" views={views} />
  </SignInView>
);
