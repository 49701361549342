import styled from 'styled-components';
import {borderRadius} from 'styles/vars';

export const FormTextarea = styled.textarea``;

export const FormTextareaWrapper = styled.div`
  border-radius: ${borderRadius.default}px;

  background: #f6f6f6;
  position: relative;
  max-height: 240px;
  display: flex;

  > pre,
  > textarea {
    font-family: inherit;
    font-size: inherit;

    margin: 0;
    padding: 0;
    resize: none;

    width: 100%;
    border: none;
    background: none;
    padding: 1rem;
    border-radius: ${borderRadius.default}px;
    min-height: 6rem;
  }

  > textarea {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);

    &:focus {
      outline: none;
      border-color: rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 0px 2px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-input-placeholder {
      opacity: 0.5;
    }
  }

  > pre {
    visibility: hidden;
    white-space: pre-wrap;
    overflow: hidden;
    max-height: 240px;
    color: #cc0000;
  }

  &:hover,
  &:focus {
    border-color: rgba(0, 0, 0, 0.4);
  }
`;
