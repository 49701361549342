import {defineMessages} from 'react-intl';

const messages = defineMessages({
  privacy: {
    id: 'privacy',
    defaultMessage: 'Privacy'
  },
  password: {
    id: 'password',
    defaultMessage: 'Password'
  },
  signOut: {
    id: 'signOut',
    defaultMessage: 'Sign out'
  }
});

export default messages;
