import React, {Component} from 'react';
import Icon from 'shared/components/Icon';

import {SubmenuItem, Label} from './styles';

class SubmenuItemComponent extends Component {
  render() {
    const {children, onClick, selected, marginIndex} = this.props;
    return (
      <SubmenuItem
        onClick={onClick}
        disabled={selected}
        className={selected ? 'selected' : ''}
        marginIndex={marginIndex}
      >
        <Label>{children}</Label>
        {selected && <Icon name={'checkmark'} size={20} />}
      </SubmenuItem>
    );
  }
}

export default SubmenuItemComponent;
