import React, {Component} from 'react';
import {defineMessages} from 'react-intl';

import CenteredMessage from 'components/Layout/CenteredMessage';
import {FormattedMessage} from 'shared/components/FormattedComponents';

import {Error} from './styles';

const messages = defineMessages({
  error_somethingSnapped: {
    id: 'error_somethingSnapped',
    defaultMessage: 'Upps, something snapped'
  }
});

class ErrorComponent extends Component {
  render() {
    return (
      <Error>
        <CenteredMessage icon={'warning'}>
          <FormattedMessage {...messages.error_somethingSnapped} />
        </CenteredMessage>
      </Error>
    );
  }
}

export default ErrorComponent;
