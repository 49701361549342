import {defineMessages} from 'react-intl';

const messages = defineMessages({
  pageTitle_action_backToAllGuides: {
    id: 'pageTitle_action_backToAllGuides',
    defaultMessage: 'back to my guides'
  }
});

export default messages;
