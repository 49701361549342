import styled, {keyframes} from 'styled-components';

const delayShow = keyframes`
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SpinnerMessage = styled.div`
  display: flex;
  animation: ${delayShow} 0.5s linear;
`;

export const SpinnerText = styled.div`
  opacity: 0.3;
  margin-left: 1rem;
  flex: 1;
  align-self: center;
  line-height: 1;
`;
