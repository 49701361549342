import styled from 'styled-components';
import {borderRadius} from 'styles/vars';

export const Card = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${borderRadius.default}px;
  &:hover {
    opacity: 0.7;
  }
`;
export const Content = styled.div`
  padding-top: 0.5rem;
  max-height: 2rem;
  overflow: hidden;
`;
