import BasePlatformStore from 'shared/stores/platform';
import {addLocaleData} from 'react-intl';
import {observable, action, computed, makeObservable} from 'mobx';
class PlatformStore extends BasePlatformStore {
  @observable name = '';
  @observable assetsFolder;
  @observable currentLanguage;
  @observable localeMessages = {};
  @observable notFound = false;

  constructor() {
    super();
    makeObservable(this);
  }

  /* START TEMPORARY DUPLICATE BETWEEN MOBILE & CLIENT */
  @observable availableLanguages;

  @action setAvailablelanguages = val => {
    this.availableLanguages = val;
    this.initIntlProvider();
  };

  @action
  initIntlProvider = () => {
    const localeData = [];
    const localeMessages = {};

    this.availableLanguages.forEach(language => {
      const shortLocale = this.getLocalMapping(language.locale);
      localeMessages[shortLocale] = require(`translations/${shortLocale}.json`);
      localeData.push(...require(`react-intl/locale-data/${shortLocale}`));
    });

    addLocaleData(localeData);
    this.localeMessages = localeMessages;
  };

  @action
  setIconsShowHintLabel = showStepNoteIconLabel => {
    this.showStepNoteIconLabel = showStepNoteIconLabel;
  };

  @action
  setCurrentLanguage = val => {
    this.currentLanguage = val;
    this.setLocalLanguage(val);
  };

  @action
  setLanguageWithoutReload = language => {
    this.setLocalLanguage(language);
    this.setCurrentLanguage(language);
  };

  @action
  setPlatformName = name => (this.name = name);

  @action
  setDomain = domain => (this.domain = domain);

  setAssetsFolder = assetsFolder => {
    this.assetsFolder = assetsFolder;
  };

  @computed
  get selectedLocale() {
    return this.availableLanguages.find(lang => lang.locale === this.currentLanguage);
  }

  @computed
  get activeLocale() {
    return this.currentLanguage ? this.currentLanguage.substring(0, 2) : this.browserLocale.substring(0, 2);
  }
  /* END TEMPORARY DUPLICATE BETWEEN MOBILE & CLIENT */

  @action setNotFound = value => (this.notFound = value);
}

export default PlatformStore;
