import React from 'react';
import {RouteAdapter as Route} from 'mobx-router-utils/RouteAdapter';
import includes from 'lodash/includes';
import snakeCase from 'lodash/snakeCase';

import store from '../stores/store';
import ExplorerView from 'views/ExplorerView';
import GuideView from 'views/GuideView';
import GuideOverview from 'components/GuideOverview';
import TopicOverview from 'components/TopicOverview';
import InstructionOverview from 'components/InstructionOverview';
import SignInOptionsPickerView from 'views/SignInOptionsPickerView';
import SignInView from 'views/SignInView';
import SearchView from 'views/SearchView';
import PhoneActivationCodeView from 'shared/views/PhoneActivationCodeView';
import ChangePasswordView from 'shared/components/ChangePasswordView';
import ResetPasswordView from 'shared/components/ResetPasswordView';
import PrivacyCentreView from 'shared/views/PrivacyCentreView';

import {CAN_USE_ADVANCED_SEARCH} from 'shared/constants';
import {DOMAIN_LOGIN_OPTIONS} from 'shared/enums';
// TODO: testing comments

const views = {
  // public
  domainLogin: new Route({
    id: 'domainLogin',
    component: <SignInOptionsPickerView />,
    path: '/domain-login'
  }),

  signIn: new Route({
    id: 'signIn',
    component: <SignInView />,
    path: '/sign-in/:loginType',
    restricted: false,
    onEnter: (route, params, store) => {
      const {loginType} = params;
      const shouldRestrictAccessToRoute =
        loginType &&
        !includes(
          [DOMAIN_LOGIN_OPTIONS.PHONE_NUMBER, DOMAIN_LOGIN_OPTIONS.EMAIL, DOMAIN_LOGIN_OPTIONS.DOMAIN_PASSWORD],
          snakeCase(loginType).toUpperCase()
        );

      if (shouldRestrictAccessToRoute) {
        store?.router.goTo(views.domainLogin, {});
      }
    }
  }),

  //restricted
  explorer: new Route({
    id: 'explorer',
    component: <ExplorerView />,
    path: '/',
    restricted: true
  }),
  search: new Route({
    id: 'search',
    component: <SearchView />,
    path: '/search-legacy',
    restricted: true,
    onEnter: (route, params, store) => {
      const {explorerPage} = store;
      explorerPage.toggleSimpleSearchDropdownMode(true);
    }
  }),
  searchAdvanced: new Route({
    id: 'searchAdvanced',
    component: <SearchView isAdvanced />,
    path: '/search',
    restricted: true,
    beforeEnter: (route, params, store) => {
      if (!CAN_USE_ADVANCED_SEARCH) {
        store.router.goTo(views.explorer, {});
        return false;
      }
    },
    onEnter: (route, params, store) => {
      const {explorerPage} = store;
      explorerPage.toggleSimpleSearchDropdownMode(false);
    }
  }),
  guideExplorer: new Route({
    id: 'redirect-explorer',
    path: '/guide',
    restricted: true,
    beforeEnter: (route, params, store) => {
      store.router.goTo(views.explorer, {});
      return false;
    }
  }),
  guide: new Route({
    id: 'guide',
    component: <GuideView SectionComponent={GuideOverview} />,
    path: '/guide/:guideSlug',
    restricted: true
  }),
  topic: new Route({
    id: 'topic',
    component: <GuideView SectionComponent={TopicOverview} />,
    path: '/guide/:guideSlug/:topicSlug',
    restricted: true
  }),
  instruction: new Route({
    id: 'instruction',
    component: <GuideView SectionComponent={InstructionOverview} />,
    path: '/guide/:guideSlug/:topicSlug/:instructionSlug',
    restricted: true
  }),
  step: new Route({
    id: 'step',
    component: <GuideView SectionComponent={InstructionOverview} />,
    path: '/guide/:guideSlug/:topicSlug/:instructionSlug/:stepId',
    restricted: true
  }),
  stepMedia: new Route({
    id: 'stepMedia',
    component: <GuideView SectionComponent={InstructionOverview} />,
    path: '/guide/:guideSlug/:topicSlug/:instructionSlug/:stepId/media',
    restricted: true
  }),
  privacy: new Route({
    id: 'privacy',
    component: <PrivacyCentreView hideCookiesSection />,
    path: '/privacy-page'
  }),
  changePassword: new Route({
    id: 'changePassword',
    component: <ChangePasswordView />,
    path: '/change-password',
    restricted: true
  }),
  requestResetPassword: new Route({
    id: 'requestResetPassword',
    component: <ResetPasswordView requestResetPassword />,
    path: '/request-reset-password'
  }),
  requestResetPasswordPhone: new Route({
    id: 'requestResetPasswordPhone',
    component: <ResetPasswordView requestResetPassword isPhone />,
    path: '/request-reset-password-phone'
  }),
  resetPassword: new Route({
    id: 'resetPassword',
    component: <ResetPasswordView />,
    path: '/reset-password'
  }),
  resetPasswordPhone: new Route({
    id: 'resetPasswordPhone',
    component: <ResetPasswordView isPhone />,
    path: '/reset-password-phone'
  }),
  setPassword: new Route({
    id: 'setPassword',
    component: <ResetPasswordView setPassword />,
    path: '/set-password'
  }),
  activationCode: new Route({
    id: 'activationCode',
    component: <PhoneActivationCodeView onSuccess={() => store.router.goTo(views.domainLogin, {})} />,
    path: '/activation-code'
  })
};

export default views;
