import React, {Component} from 'react';
import Raven from 'raven-js';
import {observable, makeObservable} from 'mobx';
import {observer, inject} from 'mobx-react';

//components
import Error from 'components/Error';

@inject('store')
@observer
class ErrorBoundaryComponent extends Component {
  @observable error;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidCatch(error, errorInfo) {
    this.error = error;
    Raven.captureException(error, {extra: errorInfo});
  }

  render() {
    if (this.error) {
      return <Error />;
    }
    return this.props.children;
  }
}

export default ErrorBoundaryComponent;
