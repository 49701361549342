import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {bindField} from 'shared/utils/input-utils';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';

//components
import ContextMenuLink from 'components/Layout/PageTitle/ContextMenuLink';
import FormTextarea from 'components/Layout/Form/FormTextarea';
import StackSpace from 'components/Layout/StackSpace';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import Spinner from 'shared/components/Spinner';
import Recaptcha from 'shared/components/Recaptcha';

//mutations
import {CreateFeedback} from 'shared/api/feedback/mutations';
import {createFeedbackOptions} from 'shared/api/feedback/mutation-options';

//queries
import {XmRealityTeamUsers} from 'shared/api/xmreality/queries';
import {xMRealityOptions} from 'shared/api/xmreality/query-options';

//messages
import messages from './messages';

//config
import {RECAPTCHA_SITE_KEY} from 'shared/constants';

import {
  Feedback,
  FeedbackContent,
  Label,
  SendButton,
  FeedbackItemWrapper,
  HeaderOptions,
  HeaderOption,
  StyledAvailableCaller,
  StyledUnavailableCaller,
  StyledCallerName,
  StyledIcon,
  StyledText,
  SpinnerWrapper,
  StyledMessageWrapper,
  StyledCallersList
} from './styles';

@inject('store')
@graphql(CreateFeedback, createFeedbackOptions)
@graphql(XmRealityTeamUsers, xMRealityOptions)
@observer
class FeedbackComponent extends Component {
  componentDidMount = async () => {
    const {
      store: {
        feedback: {setSaveLabels}
      }
    } = this.props;

    setSaveLabels(
      this.props.intl.formatMessage({...messages.feedback_send_label}),
      this.props.intl.formatMessage({...messages.feedback_sending_label})
    );
    document.body.addEventListener('click', this.handleClickOutside);
  };

  componentWillReceiveProps(nextProps) {
    const {
      store: {
        feedback: {setAvailableCallers, setCallersAreLoading, setCallersError}
      },
      xMRealityQuery
    } = nextProps;
    if (!xMRealityQuery) return;
    if (xMRealityQuery.loading) setCallersAreLoading(true);
    if (xMRealityQuery.error) setCallersError(xMRealityQuery.error);
    if (xMRealityQuery.xMRealityTeamUsers) setAvailableCallers(xMRealityQuery.xMRealityTeamUsers);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const {
      store: {feedback}
    } = this.props;
    const {toggleFeedback, opened, wrapperRef} = feedback;
    if (wrapperRef && !wrapperRef.contains(event.target) && opened) {
      toggleFeedback();
    }
  };

  submitFeedback = (createFeedbackMutation, sendFeedback) =>
    sendFeedback(
      this.recaptcha,
      createFeedbackMutation,
      this.props.intl.formatMessage({...messages.feedback_action_success}),
      this.props.intl.formatMessage({...messages.feedback_action_error})
    );

  onAvailableCallerClick = async id => {
    const {
      store: {
        router: {params}
      }
    } = this.props;

    const url = await axios.post(`/xmreality/call/${id}`, {...params}).then(response => response.data.url);

    window.open(url, '_blank');
  };

  render() {
    const {
      store: {feedback, platform},
      createFeedbackMutation
    } = this.props;
    const {
      form,
      toggleFeedback,
      opened,
      checkRecaptcha,
      sendFeedback,
      sendButtonText,
      disableFeedback,
      setWrapperRef,
      isXMRealitySelected,
      setIsXMRealitySelected,
      availableCallers,
      unavailableCallers,
      callersAreLoading,
      callersError,
      sending
    } = feedback;

    const {isContentProtected, canUseXmReality, xMRealityTeamForDomain} = platform;
    const showXmRealityForDomain = !!(canUseXmReality && xMRealityTeamForDomain);
    const showRecaptcha = !isEmpty(RECAPTCHA_SITE_KEY) && !isContentProtected;
    const showCallersError = callersError && !callersAreLoading;
    const showNoAvailableCallers =
      !callersError && !callersAreLoading && availableCallers.length === 0 && unavailableCallers.length === 0;

    const xmRealityTab = (
      <StyledCallersList>
        {callersAreLoading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
        {showCallersError && (
          <StyledMessageWrapper>
            <FormattedMessage {...messages.errorAvailableCallers} />
          </StyledMessageWrapper>
        )}
        {showNoAvailableCallers && (
          <StyledMessageWrapper>
            <FormattedMessage {...messages.noCallersAvailable} />
          </StyledMessageWrapper>
        )}
        {availableCallers.map((availableCaller, index) => (
          <StyledAvailableCaller
            key={availableCaller.id}
            href={availableCaller.link}
            target="_blank"
            style={{display: 'block'}}
            showBorder={index}
            onClick={() => this.onAvailableCallerClick(availableCaller.id)}
          >
            <StyledIcon name="phone" />
            <StyledCallerName>{availableCaller.name}</StyledCallerName>
          </StyledAvailableCaller>
        ))}
        {unavailableCallers.map((availableCaller, index) => (
          <StyledUnavailableCaller key={availableCaller.id} style={{display: 'block'}} showBorder={index}>
            <StyledCallerName>{availableCaller.name}</StyledCallerName>
          </StyledUnavailableCaller>
        ))}
      </StyledCallersList>
    );

    const feedbackTab = (
      <div>
        <Label>
          <FormattedMessage {...messages.feedback_give_us_your_feedback} />
        </Label>
        <FormTextarea {...bindField(form, 'text')} />
        <StackSpace />
        {showRecaptcha && (
          <Recaptcha
            ref={ref => (this.recaptcha = ref)}
            sitekey={RECAPTCHA_SITE_KEY}
            onResolved={() => this.submitFeedback(createFeedbackMutation, sendFeedback)}
          />
        )}
        <SendButton
          disabled={!form.isValid || sending}
          onClick={() => {
            showRecaptcha
              ? checkRecaptcha(this.recaptcha.execute)
              : this.submitFeedback(createFeedbackMutation, sendFeedback);
          }}
          isLoading={sending}
        >
          {sendButtonText}
        </SendButton>
      </div>
    );

    return (
      <FeedbackItemWrapper ref={setWrapperRef}>
        <ContextMenuLink
          onClick={toggleFeedback}
          iconName={'chat-bubble'}
          tag={'button'}
          disabled={disableFeedback}
          className={opened ? 'active' : null}
        >
          <FormattedMessage {...messages.feedback_menu_label} />
        </ContextMenuLink>
        <Feedback opened={opened}>
          <FeedbackContent shouldShowScrollBard={availableCallers.length > 5}>
            {showXmRealityForDomain && (
              <HeaderOptions>
                <HeaderOption
                  isActive={!isXMRealitySelected && showXmRealityForDomain}
                  onClick={() => setIsXMRealitySelected(false)}
                >
                  <StyledText>
                    <FormattedMessage {...messages.feedback_menu_label}>
                      {text => text.charAt(0).toUpperCase() + text.slice(1)}
                    </FormattedMessage>
                  </StyledText>
                </HeaderOption>

                {showXmRealityForDomain && (
                  <HeaderOption isActive={isXMRealitySelected} onClick={() => setIsXMRealitySelected(true)}>
                    <StyledText>
                      <FormattedMessage {...messages.feedback_xm_reality} />
                    </StyledText>
                  </HeaderOption>
                )}
              </HeaderOptions>
            )}

            {isXMRealitySelected && showXmRealityForDomain ? xmRealityTab : feedbackTab}
          </FeedbackContent>
        </Feedback>
      </FeedbackItemWrapper>
    );
  }
}

export default injectIntl(FeedbackComponent);
