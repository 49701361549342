import styled from 'styled-components';
import {zIndex, borderRadius} from 'styles/vars';

const getAlignment = p => {
  let styles = '';
  if (p.alignment === 'left') {
    styles += `
      right: auto;
      left: 0;
      &:before {
        left: 32px;
        right: auto;
      }
    `;
  }
  return styles;
};

export const SortByItemWrapper = styled.div`
  position: relative;
`;

export const SortBy = styled.div`
  display: ${p => (p.opened ? 'flex' : 'none')};
  background: #ffffff;
  position: absolute;
  right: 0;
  top: ${48 + 4}px;
  border-radius: ${borderRadius.default}px;
  z-index: ${zIndex.overlayMenu};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  min-width: 260px;
  color: #000000;
  white-space: nowrap;
  text-align: left;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    right: 8px;
    top: -8px;
    height: 16px;
    width: 16px;
    transform: rotate(45deg);
    transform-origin: 0 0;
    background: #ffffff;
    border-radius: ${borderRadius.default}px;
  }

  ${p => getAlignment(p)};
`;

export const SortByContent = styled.div`
  line-height: 48px;
  width: 100%;
  padding: 0.5rem 0.25rem;
  max-height: calc(50vh);
  overflow: auto;
`;
