import {defineMessages} from 'react-intl';

const messages = defineMessages({
  action_goToThisNextInstruction: {
    id: 'action_goToThisNextInstruction',
    defaultMessage: 'Go to this next instruction'
  },
  action_goToThisNextChecklist: {
    id: 'action_goToThisNextChecklist',
    defaultMessage: 'Go to this next checklist'
  },
  relatedInstructions: {
    id: 'relatedInstructions',
    defaultMessage: 'Related {count, plural, one {instruction} other {instructions}}'
  },
  relatedChecklists: {
    id: 'relatedChecklists',
    defaultMessage: 'Related {count, plural, one {checklist} other {checklists}}'
  },
  relatedInstructionAndChecklist: {
    id: 'relatedInstructionAndChecklist',
    defaultMessage: 'Related checklists and instructions'
  },
  pageTitle_action_backToCompleteGuide: {
    id: 'pageTitle_action_backToCompleteGuide',
    defaultMessage: 'back to complete guide'
  },
  title: {
    id: 'checklistSubmittedTitle',
    defaultMessage: 'Checklist Submitted'
  },
  subtitle: {
    id: 'checklistSubmittedSubtitle',
    defaultMessage: 'The results of your checklist have been submitted and are available in the Checklist report'
  },
  titleNotSubmitted: {
    id: 'titleNotSubmitted',
    defaultMessage: 'Checklist not submitted yet'
  },
  subtitleNotSubmitted: {
    id: 'subtitleNotSubmitted',
    defaultMessage: 'The results of your checklist have not yet been submitted'
  },
  sign_off_submitted_title: {
    id: 'sign_off_submitted',
    defaultMessage: 'Sign-off submitted'
  },
  sign_off_submitted_subtitle: {
    id: 'sign_off_submitted_subtitle',
    defaultMessage: 'Your answers for this sign-off have been submitted and are available in the Sign-off results.'
  }
});

export default messages;
