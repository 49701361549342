import styled from 'styled-components';
import Link from 'shared/components/Link';

export const brandingBarHeight = 128;

export const BrandingBarWrapper = styled.div`
  min-height: ${brandingBarHeight}px;
`;

export const StyledLink = styled(Link)`
  display: block;
  height: 128px;
  min-width: 256px;
  position: relative;
  div {
    height: 100%;
  }
  img {
    max-height: 48px;
    max-width: 480px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;
