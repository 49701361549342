//global
import {RouterStore} from 'mobx-router';
import AuthStore from 'stores/global/auth-store';
import AppStore from 'stores/global/app-store';
import PlatformStore from 'stores/global/platform-store';
import FeedbackStore from 'stores/global/feedback-store';
import NotificationStore from 'stores/global/notification-store';

//shared
import CampaignStore from 'shared/stores/campaign';
import AnalyticsStore from 'shared/stores/analytics';
import UiStore from 'shared/stores/ui';
import ChecklistStore from 'shared/stores/checklist-store';
import FeedbackDialog from 'shared/stores/dialogs/feedback-dialog';
import ToastNotificationsStore from 'shared/stores/toast-notifications';
import CookiePreferenceStore from 'shared/stores/cookie-preference';
import ForgotWorkspacePasswordDialogStore from 'shared/stores/forgot-workspace-password-dialog';
import AuthLoginStore from 'shared/stores/auth-login-store';
import ResetPasswordPage from 'shared/stores/reset-password-page';
import ReportIssueStore from 'shared/stores/report-issue-store';

//pages
import ExplorerPage from 'stores/pages/explorer-page';
import GuidePage from 'stores/pages/guide-page';
import InstructionPage from 'stores/pages/instruction-page';
import ChangePasswordPage from 'shared/stores/change-password-page';

const analytics = new AnalyticsStore();

const store = {
  //global
  router: new RouterStore(this),
  auth: new AuthStore(),
  app: new AppStore(),
  platform: new PlatformStore(),
  feedback: new FeedbackStore(),
  notification: new NotificationStore(),
  toastNotifications: new ToastNotificationsStore(),

  //shared
  campaign: new CampaignStore(),
  analytics,
  ui: new UiStore(),
  checklist: new ChecklistStore(),
  feedbackDialog: new FeedbackDialog(),
  cookiePreference: new CookiePreferenceStore({
    appName: 'VIEWER_DESKTOP',
    enablePosthog: analytics.enablePosthog,
    disablePosthog: analytics.disablePosthog
  }),
  forgotWorkspacePasswordDialog: new ForgotWorkspacePasswordDialogStore(),
  authLogin: new AuthLoginStore(),
  reportIssue: new ReportIssueStore(),

  //pages
  explorerPage: new ExplorerPage(),
  guidePage: new GuidePage(),
  instructionPage: new InstructionPage(),
  changePasswordPage: new ChangePasswordPage(),
  resetPasswordPage: new ResetPasswordPage()
};

store.analytics.setStore(store);
store.ui.setStore(store);
store.campaign.setStore(store);
store.checklist.setStore(store);
store.feedbackDialog.setStore(store);
store.platform.setStore(store);
store.cookiePreference.setNotification(store.notification);
store.changePasswordPage.setNotification(store.notification);
store.forgotWorkspacePasswordDialog.setStore(store);
store.reportIssue.setStore(store);
store.reportIssue.setNotification(store.notification);
store.auth.setNotification(store.notification);
store.resetPasswordPage.setStore(store);
store.resetPasswordPage.setNotification(store.notification);
store.checklist.setNotification(store.notification);

export default store;
