import {defineMessages} from 'react-intl';

const messages = defineMessages({
  searchPage_dropDownTitle: {
    id: 'searchPage_dropDownTitle',
    defaultMessage: 'Quick search results'
  },
  pageTitle_searchResults: {
    id: 'pageTitle_searchResults',
    defaultMessage: 'Search results'
  },
  searchPage_tags: {
    id: 'searchPage_tags',
    defaultMessage: 'Tags'
  },
  searchPage_searchResults: {
    id: 'searchPage_searchResults',
    defaultMessage: 'Search results'
  },
  searchPage_guides: {
    id: 'searchPage_guides',
    defaultMessage: 'Guides'
  },
  searchPage_instructions: {
    id: 'searchPage_instructions',
    defaultMessage: 'Instructions'
  },
  searchPage_filters: {
    id: 'searchPage_filters',
    defaultMessage: 'Filter by tags'
  },
  searchPage_simple: {
    id: 'searchPage_simple',
    defaultMessage: 'Simple Search'
  },
  searchPage_advanced: {
    id: 'searchPage_advanced',
    defaultMessage: 'Advanced Search'
  },
  searchPage_noResults: {
    id: 'searchPage_noResults',
    defaultMessage: 'No results'
  },
  searchPage_viewAllResults: {
    id: 'searchPage_viewAllResults',
    defaultMessage: 'View All Results'
  },
  error: {
    id: 'error',
    defaultMessage: 'Error'
  },
  errorFetchingResults: {
    id: 'errorFetchingResults',
    defaultMessage: 'Could not fetch the results.'
  },
  loading: {
    id: 'loading',
    defaultMessage: 'loading'
  }
});

export default messages;
