import {observable, action, computed, makeObservable} from 'mobx';
import Raven from 'raven-js';

import FeedbackContentForm from 'stores/forms/feedback-content-form';
import store from 'stores/store';
import {FEEDBACK_PARENT_TYPE_ENUM} from 'shared/enums';
import {posthogCapture} from 'shared/utils/posthog-utils';

class FeedbackStore {
  @observable opened = false;
  @observable sending = false;
  @observable saveLabel = '';
  @observable savingLabel = '';
  @observable wrapperRef = null;
  form = FeedbackContentForm();

  @observable isXMRealitySelected = false;
  @observable availableCallers = [];
  @observable unavailableCallers = [];
  @observable callersAreLoading = false;
  @observable callersError = null;

  @action
  setIsXMRealitySelected = isXMRealitySelected => (this.isXMRealitySelected = isXMRealitySelected);

  @action
  setAvailableCallers = (availableCallers = []) => {
    this.availableCallers = availableCallers.filter(caller => caller.status === 'active');
    this.unavailableCallers = availableCallers.filter(caller => caller.status !== 'active');
    this.callersAreLoading = false;
  };

  @action
  setCallersAreLoading = callersAreLoading => (this.callersAreLoading = callersAreLoading);

  @action
  setCallersError = callersError => {
    if (callersError) {
      Raven.captureException(callersError);
    }

    this.callersError = callersError;
  };

  @action
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  @action toggleFeedback = () => {
    if (this.disableFeedback) {
      return false;
    }
    this.opened = !this.opened;
    posthogCapture('Open Feedback Dialog');
  };

  @action checkRecaptcha = execute => {
    this.sending = true;
    execute();
  };

  @action setSaveLabels = (saveLabel, savingLabel) => {
    this.saveLabel = saveLabel;
    this.savingLabel = savingLabel;
  };

  @action
  reset = () => {
    this.sending = false;
    this.opened = false;
    this.form.clear();
  };

  sendFeedback = async (recaptchaRef, createFeedbackMutation, successMessage, errorMessage) => {
    const {
      notification: {success, error}
    } = store;
    this.sending = true;
    try {
      const {text} = this.form.values();
      const feedback = {...this.getFeedbackObject(), body: text};
      const {errors} = await createFeedbackMutation({feedback});
      if (errors) {
        this.reset();
        error(errorMessage);
      } else {
        this.reset();
        success(successMessage);
        posthogCapture('Submit Feedback');
      }
    } catch (e) {
      this.reset();
      error(errorMessage);
    }
    if (recaptchaRef) {
      recaptchaRef.reset();
    }
  };

  getFeedbackObject = () => {
    const {
      router: {params}
    } = store;
    const {guideSlug, instructionSlug, stepId} = params;
    const {instructionPage, guidePage} = store;

    if (stepId) {
      return {
        parentType: FEEDBACK_PARENT_TYPE_ENUM.STEP,
        parentId: instructionPage.lastVisitedStep.id
      };
    } else if (instructionSlug) {
      return {
        parentType: FEEDBACK_PARENT_TYPE_ENUM.INSTRUCTION,
        parentId: instructionPage.currentInstruction.id
      };
    } else if (guideSlug) {
      return {
        parentType: FEEDBACK_PARENT_TYPE_ENUM.GUIDE,
        parentId: guidePage.currentGuideId
      };
    } else {
      return {};
    }
  };

  constructor() {
    makeObservable(this);
  }

  @computed get sendButtonText() {
    return this.saveLabel;
  }

  @computed get disableFeedback() {
    const {
      router: {currentRoute},
      instructionPage: {lastVisitedStep}
    } = store;
    return (
      currentRoute.id === 'step' &&
      lastVisitedStep &&
      (lastVisitedStep.id === 'nextInstruction' || lastVisitedStep.id === 'campaign')
    );
  }
}

export default FeedbackStore;
