import styled from 'styled-components';

export const StickyHeaderWrapper = styled.div`
  min-height: 100vh;
  background: ${p => p.theme.mainBgColor};
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: ${p => p.theme.fontFamily};

  #ie-warning ~ #root & {
    min-height: calc(100vh - 52px);
  }
`;

//flexbug 3.2.a workaround
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
