import styled from 'styled-components';

const buttonReset = () => {
  return `
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    padding: 0; 
    border: none;
    background: none;
    color: inherit;
    margin: 0;
  `;
};

export const MenuItemButton = styled.button``;

export const MenuItemWrapper = styled.div`
  position: relative;
  min-width: 48px;

  > button {
    ${buttonReset()};
    min-width: 100%;
    min-height: 48px;
    padding: ${p => (p.type === 'text' ? '0 1rem' : 0)};
    cursor: pointer;

    > .icon {
      line-height: 1;
      display: block;
      margin: auto;
    }
    &:focus {
      outline: none;
    }
  }
  &.active > button,
  > button:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
