import {UserInfo} from 'api/user/queries';
import {observable, action, computed, makeObservable} from 'mobx';
import {GRAPHQL_URL, INVALID_LOGIN_OPTION_CODE} from 'shared/constants';
import store from 'stores/store';
import views from 'config/views';
import axios from 'axios';
import Raven from 'raven-js';

//forms
import GetDomainLoginForm from 'shared/stores/forms/domain-login-form';
import GetDomainLoginFormEmailPassword from 'shared/stores/forms/domain-login-form-email-password';
import GetDomainLoginFormPhonePassword from 'shared/stores/forms/domain-login-form-phone-password';

//enums
import {USER_ROLE_ENUMS, DOMAIN_LOGIN_FORBIDDEN_ACTIONS, DOMAIN_LOGIN_OPTIONS} from 'shared/enums';

import {posthogCapture} from 'shared/utils/posthog-utils';
import {posthogEvents} from 'shared/posthog-events';

import invoke from 'lodash/invoke';
import includes from 'lodash/includes';

import * as RedirectionUtils from 'shared/utils/redirection';

class AuthStore {
  @observable user = undefined;
  @observable loading = false;
  @observable authing = true;
  @observable loggedInButCantViewContent = false;
  @observable translations = {};
  @observable hasLoginError = true;

  //forms
  domainLoginForm = GetDomainLoginForm();
  domainLoginFormEmailPassword = GetDomainLoginFormEmailPassword();
  domainLoginFormPhoneNumberPassword = GetDomainLoginFormPhonePassword();

  setNotification = notification => (this.notification = notification);

  @action
  setTranslations = translations => (this.translations = translations);

  @action
  setAuthing = val => (this.authing = val);

  @action
  setLoading = val => (this.loading = val);

  @action
  setLoggedInButCantViewContent = val => (this.loggedInButCantViewContent = val);

  @action
  handleWhenLoggedInOptionNotAllowed = async errorMessage => {
    let reason = null;

    try {
      reason = JSON.parse(errorMessage);
    } catch (error) {
      Raven.captureException(error, {extra: {code: INVALID_LOGIN_OPTION_CODE, errorMessage}});
      return;
    }

    const actionKey = store.platform.getActionWhenLoggedInOptionNotAllowed(reason);
    const actionsMap = {
      [DOMAIN_LOGIN_FORBIDDEN_ACTIONS.REDIRECT_AND_LOGOUT]: () => this.logout(),
      [DOMAIN_LOGIN_FORBIDDEN_ACTIONS.SHOW_SSO_DIALOG]: () => store.app.setShowLogoutDialog(true)
    };

    invoke(actionsMap, actionKey);
  };

  @action
  setHasLoginError = val => (this.hasLoginError = val);

  @action
  getCurrentUser = () => {
    return axios
      .post(GRAPHQL_URL, {
        query: UserInfo.loc.source.body
      })
      .then(({data: {data}}) => {
        if (data.me) {
          if (!data.me.canViewContent) {
            return this.setLoggedInButCantViewContent(true);
          }
          this.setLoggedInButCantViewContent(false);
          const {role} = data.me;
          this.user = {
            ...data.me,
            isViewer: role === USER_ROLE_ENUMS.VIEWER
          };
        }
      })
      .finally(() => {
        this.setAuthing(false);
      });
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get shouldRedirectToExplorerAfterSignIn() {
    const {router} = store;
    const {currentRoute} = router;

    return includes(['signIn', 'domainLogin'], currentRoute.id);
  }

  handleRedirection() {
    const {router} = store;

    RedirectionUtils.redirectToQueryParam({router});

    if (this.shouldRedirectToExplorerAfterSignIn) {
      RedirectionUtils.redirectToExplorer(router, views);
    }
  }

  @action.bound
  async submitLoginForm(url, form, onSuccess) {
    try {
      store.authLogin.setErrorResponse(null);
      this.setLoading(true);

      const {status} = await axios.post(url, form.values());

      if (status === 200) {
        await onSuccess();
        this.handleRedirection();
        form.reset();
      }
    } catch (error) {
      this.handleLoginError(error);
    } finally {
      this.setLoading(false);
    }
  }

  @action.bound
  submitDomainLoginForm() {
    this.submitLoginForm('/account/login/domain', this.domainLoginForm, () => {
      posthogCapture(posthogEvents.workspacePasswordSignInSuccessful);
      this.getCurrentUser();
      store.platform.fetchPlatformFlags();
    });
  }

  @action.bound
  submitDomainLoginFormEmailPassword() {
    this.submitLoginForm('/account/login', this.domainLoginFormEmailPassword, () => {
      posthogCapture(posthogEvents.emailSignInSuccessful);
      this.getCurrentUser();
      store.platform.fetchPlatformFlags();
    });
  }

  @action.bound
  submitLoginFormPhonePassword() {
    this.submitLoginForm('/account/login', this.domainLoginFormPhoneNumberPassword, async () => {
      posthogCapture(posthogEvents.emailSignInSuccessful);
      await Promise.all([this.getCurrentUser(), store.platform.fetchPlatformFlags()]);
    });
  }

  @action
  logout = () => {
    axios.get('/account/logout').then(({status, data}) => {
      if (status === 200) {
        if (data.result === 'redirect') {
          window.location.href = data.url;
        } else {
          this.setLoggedInButCantViewContent(false);
          this.user = null;
          this.setAuthing(false);
          store.app.setShowLogoutDialog(false);
          store.router.goTo(views.domainLogin, {});
        }
      }
    });
  };

  @action
  handleLoginError = ({response = {}}) => {
    store.authLogin.setErrorResponse(response);
    this.setLoading(false);
    if (!this.hasLoginError) {
      this.notification.error(this.translations.cantSignInToWorkspace);
    }
  };

  @computed get showAuthButtons() {
    const {platform} = store;
    return (!!this.user && platform.isContentProtected) || this.loggedInButCantViewContent;
  }
  @computed get isOnlyOneLoginOption() {
    const {platform} = store;
    const {
      sso,
      domain: {loginOptions},
      developmentFeatureFlags: {contactMethodSelection}
    } = platform;

    const showSSOLogin = Boolean(sso);
    const areLoginOptionsDefined = Boolean(loginOptions);
    const isThereOnlyOneLoginOption = areLoginOptionsDefined && loginOptions.length === 1;

    if (isThereOnlyOneLoginOption && loginOptions[0] === DOMAIN_LOGIN_OPTIONS.EMAIL && contactMethodSelection) {
      // phone number login comes by default with EMAIL login
      return false;
    }

    return isThereOnlyOneLoginOption && !showSSOLogin;
  }
}

export default AuthStore;
