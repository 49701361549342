import React, {Component} from 'react';

//styled-components
import {StackSpace} from './styles';

class StackSpaceComponent extends Component {
  render() {
    const {size = 2} = this.props;
    return <StackSpace size={size} />;
  }
}

export default StackSpaceComponent;
