import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {observable, action, computed, makeObservable} from 'mobx';

// styles
import {StickyHeaderWrapper, Wrapper} from './styles';
import {brandingBarHeight} from 'components/Header/BrandingBar/styles';

@inject('store')
@observer
class StickyHeaderComponent extends Component {
  @observable stickHeader = false;
  @action setHeaderSticky = val => (this.stickHeader = val);

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get isInInstruction() {
    const {id} = this.props.store.router.currentRoute;
    switch (id) {
      case 'instruction':
      case 'step':
      case 'stepFeedback':
        return true;
      default:
        return false;
    }
  }

  onScrollHandler() {
    if (this.stickHeader === false) {
      if (window.scrollY > brandingBarHeight) {
        this.setHeaderSticky(true);
      }
    } else if (!this.isInInstruction) {
      if (window.scrollY < brandingBarHeight) {
        this.setHeaderSticky(false);
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScrollHandler.bind(this), {passive: true});
  }

  componentWillReceiveProps() {
    if (this.isInInstruction) {
      this.setHeaderSticky(true);
    } else {
      this.setHeaderSticky(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollHandler.bind(this));
  }

  render() {
    const {loading, children, header} = this.props;

    if (loading) {
      return null;
    }

    return (
      <Wrapper ref={ref => (this.wrapper = ref)}>
        <StickyHeaderWrapper>
          {header({
            stick: this.stickHeader,
            isInInstruction: this.isInInstruction
          })}
          {children}
        </StickyHeaderWrapper>
      </Wrapper>
    );
  }
}

export default StickyHeaderComponent;
