import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import MaxWidthContainer from 'components/Layout/MaxWidthContainer';

import Step from 'stores/models/step';

import {getChecklistFromLocalStorage} from 'shared/utils/checklist-utils';
import {INSTRUCTION_TYPE} from 'shared/enums';

import {StepsOverview, StepOverviewCard, StepOverviewCardWrapper} from './styles';

@inject('store')
@observer
class StepsOverviewComponent extends Component {
  componentDidMount = () => {
    const {
      store: {
        instructionPage: {
          currentInstruction: {type, id}
        },
        checklist: {setChecklist}
      }
    } = this.props;

    const isChecklist = type === INSTRUCTION_TYPE.CHECKLIST;
    if (isChecklist) {
      const checklist = getChecklistFromLocalStorage(id);
      setChecklist(checklist);
      this.setState({checklistStoreUpdated: true});
    }
  };

  componentWillMount() {
    const {
      store: {
        instructionPage: {trackStepsOverviewView}
      }
    } = this.props;

    trackStepsOverviewView();
  }

  render() {
    const {steps} = this.props;

    return (
      <MaxWidthContainer>
        <StepsOverview>
          {steps.map((step, index) => (
            <StepOverviewCardWrapper key={index}>
              <StepOverviewCard step={new Step(step)} />
            </StepOverviewCardWrapper>
          ))}
        </StepsOverview>
      </MaxWidthContainer>
    );
  }
}

export default StepsOverviewComponent;
