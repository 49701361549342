import {observable, makeObservable} from 'mobx';

class GuidePage {
  @observable lastVisitedInstructionSlug;
  @observable lastVisitedTopicSlug;
  backByButton = false;
  backByPlatformLink = false;
  backByLogo = false;
  currentGuideId;

  setBackByButton = flag => (this.backByButton = flag);

  resetBackByButton = () => (this.backByButton = false);

  setBackByPlatformLink = flag => (this.backByPlatformLink = flag);

  resetBackByPlatformLink = () => (this.backByPlatformLink = false);

  setBackByLogo = flag => (this.backByLogo = flag);

  resetBackByLogo = () => (this.backByLogo = false);

  setCurrentGuideId = id => (this.currentGuideId = id);

  constructor() {
    makeObservable(this);
  }
}

export default GuidePage;
