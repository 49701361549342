import React, {Component} from 'react';
import {inject} from 'mobx-react';

//helpers
import views from 'config/views';

//styled-components
import {InstructionRow} from './styles';

@inject('store')
class InstructionRowComponent extends Component {
  render() {
    const {instruction, store, topicSlug} = this.props;
    const {slug: instructionSlug} = instruction;
    const {guidePage, router} = store;
    const {lastVisitedInstructionSlug} = guidePage;
    const {guideSlug} = router.params;

    const id = instructionSlug === lastVisitedInstructionSlug ? 'last-visited-instruction' : undefined;

    const params = {guideSlug, topicSlug, instructionSlug};

    return <InstructionRow id={id} instruction={instruction} store={store} route={views.instruction} params={params} />;
  }
}

export default InstructionRowComponent;
