import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

import InstructionsList from 'components/InstructionsList';
import MaxWidthContainer from 'components/Layout/MaxWidthContainer';
import BackLink from 'components/Layout/PageTitle/BackLink';
import Placeholder from 'components/Layout/Placeholder';
import StackSpace from 'components/Layout/StackSpace';
import PageTitle from 'components/Layout/PageTitle';
import NotFoundComponent from 'components/NotFoundComponent';
import SpinnerMessage from 'components/Layout/SpinnerMessage';
import Feedback from 'components/Feedback';
import ContextMenu from 'components/Layout/PageTitle/ContextMenu';

import {FormattedMessage} from 'shared/components/FormattedComponents';
import {HeadingDesktop as Heading} from 'shared/components/Heading';
import RestoredScroll from 'shared/components/RestoredScroll';

import {GuideOverview} from 'api/guides/queries';
import {getGuideOverviewOptions} from 'api/guides/query-options';

import views from 'config/views';

import eventsMap from 'shared/analytics-event-mappings';

import messages from './messages';
import {TopicsWrapper, TopicWrapper} from './styles';

@inject('store')
@graphql(GuideOverview, getGuideOverviewOptions)
@observer
class GuideOverviewComponent extends Component {
  componentWillReceiveProps(nextProps) {
    const {store} = nextProps;
    if (!nextProps.guide) {
      return;
    }
    const {refetch, guide, loading} = nextProps.guide;
    if (refetch) {
      const {app} = store;
      app.addRefetchQuery({name: 'guide', query: nextProps.guide.refetch});
    }
    if (!loading && guide) {
      const {guidePage, analytics} = store;
      analytics.trackPageViewEvent({
        objectsInfo: {guideId: guide.id}
      });
      guidePage.setCurrentGuideId(guide.id);
    }
  }

  componentWillUnmount() {
    const {
      store: {app, router, analytics, guidePage}
    } = this.props;
    const {guide, loading} = this.props.guide;
    app.removeRefetchQuery('guide');
    guidePage.setCurrentGuideId();

    if (!loading && guide && router.currentRoute.id === views.explorer.id) {
      const {backByButton, backByPlatformLink, backByLogo} = guidePage;
      let eventAction = eventsMap['backByReturnKey'];
      if (backByButton) {
        eventAction = eventsMap['backByButton'];
      } else if (backByPlatformLink) {
        eventAction = eventsMap['backByPlatformLink'];
      } else if (backByLogo) {
        eventAction = eventsMap['backByLogo'];
      }
      analytics.trackActionEvent({
        ...eventAction,
        objectsInfo: {guideId: guide.id}
      });
      guidePage.resetBackByButton();
      guidePage.resetBackByPlatformLink();
      guidePage.resetBackByLogo();
    }
  }

  getGoToTopicParams = topicSlug => {
    const {
      store: {router}
    } = this.props;
    const guideSlug = router.params.guideSlug;
    return {guideSlug, topicSlug};
  };

  getFallbackScrollPosition() {
    const lastVisitedInstructionElement = document.getElementById('last-visited-instruction');

    if (lastVisitedInstructionElement) {
      const {offsetHeight, offsetTop} = lastVisitedInstructionElement;
      const scrollY = offsetTop - (window.innerHeight - offsetHeight) / 2;

      return {scrollX: 0, scrollY};
    }

    return {scrollX: 0, scrollY: 0};
  }

  setBackByButton = () => {
    const {
      store: {guidePage}
    } = this.props;
    guidePage.setBackByButton(true);
  };

  render() {
    const {store} = this.props;
    const {
      platform: {hasFeedbackEnabled, canUseXmReality, xMRealityTeamForDomain}
    } = store;
    const {loading, error, guide} = this.props.guide;
    const hasXmRealityEnabledForDomain = !!(canUseXmReality && xMRealityTeamForDomain);
    if (error) {
      return <h1>Failed to load guide. Please try again.</h1>;
    }

    const backLink = (
      <BackLink route={views.explorer} store={store} onClickReaction={this.setBackByButton}>
        <FormattedMessage {...messages.pageTitle_action_backToAllGuides} />
      </BackLink>
    );
    if (loading) {
      // return (<h1>Loading guide...</h1>);
      return (
        <MaxWidthContainer>
          <PageTitle>
            <Placeholder>
              <Heading>Guide</Heading>
            </Placeholder>
            {backLink}
          </PageTitle>
          <SpinnerMessage />
        </MaxWidthContainer>
      );
    }
    if (!guide) {
      return <NotFoundComponent />;
    }
    const {title} = guide;
    return (
      <MaxWidthContainer>
        <RestoredScroll fallback={this.getFallbackScrollPosition} />
        <PageTitle>
          <Heading>{title}</Heading>
          {backLink}
          {hasFeedbackEnabled && (
            <ContextMenu>
              <Feedback canUseXmReality={hasXmRealityEnabledForDomain} />
            </ContextMenu>
          )}
        </PageTitle>
        <TopicsWrapper>
          {guide.topics.map(topic => (
            <TopicWrapper key={topic.id}>
              <InstructionsList topic={topic} />
            </TopicWrapper>
          ))}
        </TopicsWrapper>
        <StackSpace size={4} />
      </MaxWidthContainer>
    );
  }
}

export default GuideOverviewComponent;
