import React, {Component} from 'react';

//styled-components
import {SpinnerMessage, SpinnerText} from './styles';

import Spinner from 'shared/components/Spinner';

class SpinnerMessageComponent extends Component {
  render() {
    const {message = 'loading'} = this.props;
    return (
      <SpinnerMessage>
        <Spinner />
        <SpinnerText>{message}</SpinnerText>
      </SpinnerMessage>
    );
  }
}

export default SpinnerMessageComponent;
