import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import GuideCard from '../GuideCard';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {GuidesList as GuidesListQuery} from 'api/guides/queries';
import {getGuidesListOptions} from 'api/guides/query-options';

//components
import {HeadingDesktop as Heading} from 'shared/components/Heading';
import MaxWidthContainer from 'components/Layout/MaxWidthContainer';
import PageTitle from 'components/Layout/PageTitle';
import SpinnerMessage from 'components/Layout/SpinnerMessage';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import SortBy from 'components/SortByComponent';
import ContextMenu from 'components/Layout/PageTitle/ContextMenu';

//styled-components
import {GuidesList, GuidesListWrapper, GuideCardWrapper, StyledInfiniteScroll} from './styles';

import messages from './messages';

@inject('store')
@graphql(GuidesListQuery, getGuidesListOptions)
@observer
class GuidesListComponent extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.guides && nextProps.guides.refetch) {
      const {
        store: {app}
      } = nextProps;
      app.addRefetchQuery({name: 'guides', query: nextProps.guides.refetch});
    }
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.removeRefetchQuery('guides');
  }

  fetchMore = () => {
    const {guides} = this.props;
    const {fetchMore, loading, pagedGuides} = guides;
    const {nextOffset} = pagedGuides;

    if (loading || !nextOffset) {
      return;
    }

    fetchMore({
      variables: {offset: nextOffset},
      updateQuery: (previousResult, {fetchMoreResult}) => {
        const {guides, nextOffset} = fetchMoreResult.pagedGuides;

        return {
          pagedGuides: {
            __typename: previousResult.pagedGuides.__typename,
            guides: guides.length
              ? [...previousResult.pagedGuides.guides, ...guides]
              : previousResult.pagedGuides.guides,
            nextOffset
          }
        };
      }
    });
  };

  render() {
    const {guides} = this.props;
    const {loading, error, pagedGuides = {}} = guides;
    const {nextOffset} = pagedGuides;

    if (error) {
      return (
        <h1>
          <FormattedMessage {...messages.loading_failed_to_load_guide} values={{guideCount: 2}} />
        </h1>
      );
    }

    return (
      <GuidesList>
        <MaxWidthContainer>
          <PageTitle>
            <Heading size={'xlarge'} tag={'h2'}>
              <FormattedMessage {...messages.pageTitle_allGuides} />
            </Heading>
            <ContextMenu>
              <SortBy />
            </ContextMenu>
          </PageTitle>
          {pagedGuides.guides && pagedGuides.guides.length ? (
            <GuidesListWrapper>
              {pagedGuides.guides.map(guide => (
                <GuideCardWrapper key={guide.id}>
                  <GuideCard guide={guide} imageSizes="calc(25vw - 80px)" />
                </GuideCardWrapper>
              ))}
              {nextOffset && <StyledInfiniteScroll isLoading={loading} onBottom={this.fetchMore} threshold={200} />}
            </GuidesListWrapper>
          ) : (
            loading && <SpinnerMessage />
          )}
        </MaxWidthContainer>
      </GuidesList>
    );
  }
}

export default GuidesListComponent;
