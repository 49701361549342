import styled from 'styled-components';

import {FormButtonDesktop as $FormButton} from 'shared/components/FormButton';
import {zIndex, borderRadius} from 'styles/vars';
import Icon from 'shared/components/Icon';
import {commonPalette} from 'shared/styles/palette';

export const FeedbackItemWrapper = styled.div`
  position: relative;
`;

export const FeedbackContent = styled.div`
  background: #ffffff;
  padding: 2rem;
  width: 300px;
  flex-direction: column;
  border-radius: ${borderRadius.default}px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 16px rgba(0, 0, 0, 0.1);
  margin-right: -1.5rem;
  max-height: 350px;
  ${({shouldShowScrollBard}) => (shouldShowScrollBard ? 'overflow: scroll;' : '')}
  word-break: break-all;
  display: flex;
`;

export const Feedback = styled.div`
  position: absolute;
  right: 50%;
  top: calc(100% + 1rem);
  display: ${p => (p.opened ? 'flex' : 'none')};
  z-index: ${zIndex.feedbackModal};
  min-width: 260px;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0px;
    top: -8px;
    height: 16px;
    width: 16px;
    transform: rotate(-45deg);
    transform-origin: 16px 0;
    background: #ffffff;
    border-radius: ${borderRadius.default}px;
    z-index: ${zIndex.feedbackModal};
  }
`;

export const Label = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;

export const SendButton = styled($FormButton)`
  &:disabled {
    opacity: 0.5;
  }
  &:not(:disabled):hover {
    cursor: pointer;
  }
`;

export const HeaderOptions = styled.div`
  margin-bottom: 1rem;
  display: flex;
  border-bottom: 1px solid ${commonPalette.lightGrey};
`;

export const HeaderOption = styled.div`
  padding: 5px;
  ${({isActive}) => (isActive ? `border-bottom: 3px solid ${commonPalette.sgGreen};` : '')}
  cursor: pointer;

  &:hover {
    background: ${commonPalette.lightGrey};
  }
`;

export const StyledCallersList = styled.div`
  min-height: 0;
  flex: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const StyledAvailableCaller = styled.div`
  padding: 15px 0 15px 5px;
  cursor: pointer;
  ${({showBorder}) => (showBorder ? `border-top: 1px solid ${commonPalette.lightGrey};` : '')}

  &:hover {
    background: ${commonPalette.lightGrey};
  }
`;

export const StyledUnavailableCaller = styled.div`
  color: ${commonPalette.txLightGrey} !important;
  padding: 15px 0 15px 5px;
  ${({showBorder}) => (showBorder ? `border-top: 1px solid ${commonPalette.lightGrey};` : '')}

  &:hover {
    background: ${commonPalette.lightGrey};
  }
`;

export const StyledCallerName = styled.span`
  margin-left: 10px;
`;

export const StyledUnavailableCallerName = styled.span`
  color: ${commonPalette.txLightGrey}
  margin-left: 10px;
`;

export const StyledIcon = styled(Icon)`
  margin-top: -5px;
`;

export const StyledText = styled.div`
  padding: 10px;
`;

export const SpinnerWrapper = styled.div`
  text-align: center;
`;

export const StyledMessageWrapper = styled.div`
  margin-top: 20px;
`;

export const StyledLink = styled.a`
  display: flex;
  padding-top: 10px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
`;
