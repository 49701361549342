import React, {Component} from 'react';

//components
import MenuBar from './MenuBar';
import BrandingBar from './BrandingBar';

//styled-components
import {HeaderWrapper} from './styles';

class HeaderComponent extends Component {
  render() {
    return (
      <HeaderWrapper>
        {this.props.inInstruction || <BrandingBar />}
        <MenuBar stick={this.props.stick} />
      </HeaderWrapper>
    );
  }
}

export default HeaderComponent;
