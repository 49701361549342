import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import enhanceWithClickOutside from 'react-click-outside';

//components
import {SortByItemWrapper, SortBy, SortByContent} from './styles';
import ContextMenuLink from 'components/Layout/PageTitle/ContextMenuLink';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import SubmenuItem from 'components/SubmenuItem';

//messages
import messages from './messages';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

@inject('store')
@enhanceWithClickOutside
@observer
class SortByComponent extends Component {
  componentDidMount() {
    document.body.addEventListener('click', this.handleClickOutside);
    const {
      store: {
        explorerPage: {setTranslations}
      },
      intl: {formatMessage}
    } = this.props;

    const translations = mapValues(pick(messages, ['guideTitle', 'lastPublished']), message => formatMessage(message));

    setTranslations(translations);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const {
      store: {explorerPage}
    } = this.props;
    const {toggleSortBy, sortByOpened, wrapperRef} = explorerPage;

    if (wrapperRef && !wrapperRef.contains(event.target) && sortByOpened) {
      toggleSortBy();
    }
  };

  changeSortBy = id => {
    const {
      store: {explorerPage, app}
    } = this.props;
    explorerPage.changeSortBy(id);
    app.refetchGuides(id);
  };

  render() {
    const {
      store: {explorerPage}
    } = this.props;
    const {sortByOptions, sortBy, toggleSortBy, sortByOpened, setWrapperRef} = explorerPage;

    return (
      <SortByItemWrapper ref={setWrapperRef}>
        <ContextMenuLink iconName={'sort-ascending'} tag={'button'} onClick={toggleSortBy}>
          <FormattedMessage {...messages.sort_by_menu_label} />
        </ContextMenuLink>
        <SortBy opened={sortByOpened}>
          <SortByContent>
            {sortByOptions.map((option, index) => (
              <SubmenuItem onClick={() => this.changeSortBy(option.id)} key={index} selected={sortBy === option.id}>
                {option.name}
              </SubmenuItem>
            ))}
          </SortByContent>
        </SortBy>
      </SortByItemWrapper>
    );
  }
}

export default injectIntl(SortByComponent);
