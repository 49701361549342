import React, {Component} from 'react';

//styled-components
import {Placeholder} from './styles';

class PlaceholderComponent extends Component {
  render() {
    const {children} = this.props;
    return <Placeholder>{children}</Placeholder>;
  }
}

export default PlaceholderComponent;
