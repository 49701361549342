import {defineMessages} from 'react-intl';

const messages = defineMessages({
  formFieldPlaceholder_explorerSearch: {
    id: 'formFieldPlaceholder_explorerSearch',
    defaultMessage: 'Search guides'
  },
  formFieldPlaceholder_searchGuidesOrInstructions: {
    id: 'formFieldPlaceholder_searchGuidesOrInstructions',
    defaultMessage: 'Search for guides'
  }
});

export default messages;
