import {action, computed, observable, reaction, makeObservable} from 'mobx';

//helpers
import views from 'config/views';
import eventsMap from 'shared/analytics-event-mappings';
import store from 'stores/store';
import {SORT_BY_OPTIONS} from 'shared/enums';
import {LOCAL_STORAGE_KEY_SORT_BY} from 'shared/constants';

//models
import FilterContentForm from 'stores/forms/filter-content-form';

//lodash
import uniq from 'lodash/uniq';
import size from 'lodash/size';

class ExplorerPage {
  /**
   * Indicates whether the simple search API should be used in the search
   * drop-down. To use the Elastic Search API set to `false`. This value won't
   * affect the search _view_ - that one relies on the router.
   */
  @observable shouldUseSimpleSearchDropdownMode = false;
  @observable isUserTyping = false;
  @observable shouldSearchInputBeVisible = false;
  @observable selectedTags = [];
  filterContentForm = new FilterContentForm();
  tagRegex = /\s?tag\:/; // eslint-disable-line
  @observable sortBy = localStorage.getItem(LOCAL_STORAGE_KEY_SORT_BY) || SORT_BY_OPTIONS.PUBLISHED_TITLE;
  @observable sortByOpened = false;
  @observable wrapperRef = null;
  @observable isTagPickerDialogOpen = false;
  @observable.ref allTags = [];
  @observable.ref translations = {};

  disposeUserTypingReaction = null;

  @action
  setTranslations = translations => (this.translations = translations);

  constructor() {
    makeObservable(this);
  }

  @computed get sortByOptions() {
    return [
      {
        id: SORT_BY_OPTIONS.PUBLISHED_TITLE,
        name: this.translations.guideTitle
      },
      {
        id: SORT_BY_OPTIONS.LAST_PUBLISHED,
        name: this.translations.lastPublished
      }
    ];
  }

  startTrackingUserTypingInSearchInput() {
    this.disposeUserTypingReaction = reaction(
      () => this.isUserTyping,
      () => {
        if (this.isUserTyping) {
          setTimeout(() => {
            this.setIsUserTyping(false);
          }, 500);
        }
      }
    );
  }
  @computed get searchTerm() {
    const {searchTerm} = this.filterContentForm.values();
    return searchTerm && searchTerm.trim() ? searchTerm : '';
  }

  @action setIsUserTyping = value => (this.isUserTyping = value);

  @action setSearchTerm = value => {
    this.filterContentForm.$('searchTerm').sync(value);
  };

  @computed get searchTermFromURL() {
    const {
      router: {queryParams}
    } = store;
    if (!queryParams || !queryParams.q) return '';

    return decodeURIComponent(queryParams.q).split(this.tagRegex, 1)[0];
  }

  @computed get tagsFromURL() {
    const {
      router: {queryParams}
    } = store;
    if (!queryParams || !queryParams.q) return [];

    const query = decodeURIComponent(queryParams.q);
    if (!this.tagRegex.test(query)) return [];

    const tagTitles = query.split(this.tagRegex);
    tagTitles.shift();

    return tagTitles;
  }

  @computed get allSelectedTags() {
    return uniq([...this.tagsFromURL, ...this.selectedTags]);
  }

  @computed get canPerformSearch() {
    if (!this.shouldSearchInputBeVisible) {
      return false;
    }

    return this.allSelectedTags.length || (!this.isUserTyping && !!this.searchTerm && size(this.searchTerm) > 3);
  }

  @computed get onSearchPage() {
    const {router} = store;
    return router.currentRoute.id === 'searchAdvanced';
  }

  @computed get tagFilterQuery() {
    const titles = this.allSelectedTags.map(tag => ` tag:${tag}`);
    return titles.join('');
  }

  @computed get searchOnlyTags() {
    return this.tagRegex.test(this.searchTerm);
  }

  @computed get searchQuery() {
    if (!this.tagFilterQuery && !this.searchTerm) return null;

    const searchQuery = {
      q: `${this.searchTerm}${this.tagFilterQuery}`
    };

    return searchQuery;
  }

  @action
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  @action
  toggleSortBy = () => {
    this.sortByOpened = !this.sortByOpened;
  };

  @action
  changeSortBy = value => {
    this.sortBy = value;
    localStorage.setItem(LOCAL_STORAGE_KEY_SORT_BY, value);
  };

  @action
  clearSearch = cancelBy => {
    this.filterContentForm.$('searchTerm').clear();

    if (!cancelBy) {
      return;
    }

    store.analytics.trackSearchEvent({
      ...eventsMap['cancelSearch'],
      actionContent: cancelBy,
      objectsInfo: {explorer: 1}
    });
  };

  @action
  goToExplorerPage = () => {
    this.setSearchTerm('');
    store.router.goTo(views.explorer, {});
  };

  @action
  goToSearchPage = newQueryParams => {
    if (!this.searchQuery && !newQueryParams) return this.goToExplorerPage();

    this.toggleSearchInputVisibility(false);

    const view = this.shouldUseSimpleSearchDropdownMode ? views.search : views.searchAdvanced;
    store.router.goTo(view, {}, newQueryParams || this.searchQuery);
  };

  @action
  addSelectedTag = tag => {
    if (!this.selectedTags.includes(tag) && tag !== '') {
      this.selectedTags = [tag, ...this.selectedTags];
    }

    if (this.searchOnlyTags) this.removeTagFromSearchTerm();

    if (this.onSearchPage) this.goToSearchPage();
  };

  @action
  setSelectedTags = tagNames => {
    this.selectedTags = tagNames;
  };

  setSelectedTagsAndRefresh = tagNames => {
    this.setSelectedTags(tagNames);
    this.goToSearchPage();
  };

  @action
  addTagFromSearchTerm = () => {
    if (!this.searchOnlyTags) return;

    const newTag = this.searchTerm.split(this.tagRegex)[1];
    this.addSelectedTag(newTag);
  };

  @action
  removeTagFromSearchTerm = () => {
    const updatedSearchTerm = this.searchTerm.split(this.tagRegex)[0];
    this.setSearchTerm(updatedSearchTerm);
  };

  makeQueryParams = (tags, searchTerm) => {
    const parsedTags = tags.map(tag => `tag:${tag}`).join(' ');
    const queryParams = {q: `${searchTerm}${parsedTags ? ` ${parsedTags}` : ''}`};
    return queryParams;
  };

  @action
  removeSelectedTag = tag => {
    this.selectedTags = this.selectedTags.filter(t => t !== tag);
    const filteredTagsFromUrl = this.tagsFromURL.filter(t => t !== tag);
    const queryParams = this.makeQueryParams(filteredTagsFromUrl, this.searchTermFromURL || '');

    if (this.onSearchPage && queryParams.q === '') {
      this.goToExplorerPage();
      return;
    }

    if (this.onSearchPage) {
      this.goToSearchPage(queryParams);
    }
  };

  @action
  reset = () => {
    this.filterContentForm.reset();
  };

  @action
  toggleSearchInputVisibility = (value = !this.shouldSearchInputBeVisible) => {
    this.shouldSearchInputBeVisible = value;
  };

  /**
   * Sets whether the simple search API should be used in the search drop-down.
   *
   * @param value - `true` to use the simple search API, `false` - to use the
   * Elastic Search one.
   */
  @action
  toggleSimpleSearchDropdownMode = (value = !this.shouldUseSimpleSearchDropdownMode) => {
    this.shouldUseSimpleSearchDropdownMode = value;
  };

  onSearchTermChange = event => {
    store.analytics.trackSearchEvent({
      ...eventsMap['search'],
      actionContent: event.target.value,
      objectsInfo: {explorer: 1}
    });
  };

  @action setIsTagPickerDialogOpen = isOpen => {
    this.isTagPickerDialogOpen = isOpen;
  };

  @action setAllTags = allTags => {
    this.allTags = allTags;
  };
}

export default ExplorerPage;
