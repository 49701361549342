import store from 'stores/store';
import {ApolloClient, InMemoryCache, HttpLink} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import get from 'lodash/get';
import first from 'lodash/first';
import {GRAPHQL_URL} from 'shared/constants';
import {hasInvalidDomainLoginOptionError} from 'shared/utils/data-utils';

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'same-origin'
});

const checkAuth = onError(({graphQLErrors, networkError}) => {
  const isForbidden = get(first(graphQLErrors), 'extensions.code') === 'FORBIDDEN';
  if (isForbidden) {
    return store.auth.logout();
  }

  const isDomainLoginOptionInvalid = hasInvalidDomainLoginOptionError(graphQLErrors);
  if (isDomainLoginOptionInvalid) {
    const message = get(first(graphQLErrors), 'message');
    store.auth.handleWhenLoggedInOptionNotAllowed(message);
    return;
  }

  const errorMessage = get(networkError, 'result.error', '');
  if (errorMessage.match('Platform|subDomain|Domain not found!')) {
    return store.platform.setNotFound(true);
  }

  if (graphQLErrors || networkError) {
    store.auth.getCurrentUser();
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};

const client = new ApolloClient({
  link: checkAuth.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions
});

export {client};
