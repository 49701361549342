import styled from 'styled-components';

//helpers
import {borderRadius} from 'styles/vars';

//components
import $Icon from 'shared/components/Icon';
import {ResultRowDesktop as $ResultRow} from 'shared/components/ResultRow';
import $Spinner from 'shared/components/Spinner';
import $Tag from 'shared/components/Tag';

export const SearchBarDropdown = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  background-color: #fff;
  color: #000;
  max-height: 500px;
  font-family: Lato;
  line-height: 16px;
  border-radius: ${borderRadius.default}px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 16px 0 rgba(0, 0, 0, 0.1);
  overflow: auto;
`;

export const DropdownTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 32px;
  padding: 8px 0;
  white-space: nowrap;
`;

export const DropdownTitleInner = styled.div`
  flex-grow: 1;
  padding: 0 16px;
`;

export const ModeToggle = styled.button.attrs({
  type: 'button'
})`
  background: none;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  font-size: inherit;
  line-height: 32px;
  margin-right: 8px;
  outline: 0;
  padding: 0 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const Content = styled.div`
  ${p =>
    p.isTransparent &&
    `
    opacity: 0.2;
    pointer-events: none;
  `}
`;

export const Spinner = styled($Spinner).attrs({
  centerAbs: true,
  centerX: true,
  centerY: true
})`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const SubSectionWrapper = styled.div``;

export const SubSection = styled.div`
  background-color: #f0f0f0;
  height: 100%;
  font-size: 11px;
  text-transform: uppercase;
  padding: 8px 16px;
`;

export const StyledResultRow = styled($ResultRow)`
  box-shadow: none;

  &:hover {
    background: #f6f6f6;
  }
`;

export const NoResults = styled.div`
  opacity: 0.2;
  padding: 16px;
`;

export const GenericWrapper = styled.div`
  padding: 16px;
  text-align: ${p => p.align || 'left'};
`;

export const MoreLink = styled.span`
  color: #2f542c;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const Tag = styled($Tag)`
  margin: 0 16px 16px 0;
  ${p =>
    p.theme.buttonColor === '#ffffff' &&
    `
    border: 1px solid #000
  `}
`;

export const TagWrapper = styled.div`
  display: flex;
  padding: 16px 16px 0 16px;
  flex-wrap: wrap;
`;

export const WarningIcon = styled($Icon).attrs({
  name: 'warning',
  size: 20
})`
  margin: -2px 4px 0 0;
  vertical-align: -4px;
`;
