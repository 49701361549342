import React from 'react';
import {observable, action, makeObservable} from 'mobx';
import Color from 'color';
import views from 'config/views';
import {FONT_FAMILIES} from 'shared/theme-config';

//lodash
import compact from 'lodash/compact';

//components
import Link from 'shared/components/Link';

const customFontFamily = 'SwipeGuideCustomFont';

class AppStore {
  @observable theme = {};
  @observable breadcrumbs = [];
  refetchQueries = {};
  @observable views = {};
  @observable showLogoutDialog = false;

  @action
  setViews = views => (this.views = views);

  @action
  setShowLogoutDialog = showLogoutDialog => (this.showLogoutDialog = showLogoutDialog);

  @action
  setTheme = theme => {
    const fullTheme = {...theme};
    const mainColor = theme.mainColor;
    const colorMain = Color(mainColor);
    const colorContrast = colorMain.contrast(Color('#ffffff'));
    // const colorIsLight = colorContrast < 2;

    fullTheme.main = mainColor;
    fullTheme.mainDarker = colorMain.darken(0.2).string();
    fullTheme.menubarTextColor = colorContrast < 2 ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,1)';
    fullTheme.menubarSearchPlaceholder = Color(fullTheme.menubarTextColor).fade(0.3).string();
    fullTheme.submenuTickColor = colorContrast < 2 ? '#000000' : mainColor;
    fullTheme.hintColor = colorContrast < 2 ? '#000000' : mainColor;
    fullTheme.mainBgColor = '#FAFAFA';
    fullTheme.linkColor = mainColor;
    fullTheme.linkColorHover = fullTheme.mainDarker;

    if (theme.fontFamily === 'Custom') {
      fullTheme.fontFamily = compact([customFontFamily, theme.customFontFallback]).join();
      fullTheme.fontStylesheet = this.generateCustomFontStylesheet(theme);
    } else {
      fullTheme.fontFamily = FONT_FAMILIES[theme.fontFamily].style;
      fullTheme.fontStylesheet = this.generatePresetFontStylesheet(theme);
    }

    this.theme = fullTheme;
  };

  generateCustomFontStylesheet = theme => {
    const declarations = [];

    if (theme.customFontRegularUrl) {
      declarations.push(this.generateFontFaceDeclaration(theme.customFontRegularUrl, 'normal', 'normal'));
    }

    if (theme.customFontBoldUrl) {
      declarations.push(this.generateFontFaceDeclaration(theme.customFontBoldUrl, 'bold', 'normal'));
    }

    if (theme.customFontItalicUrl) {
      declarations.push(this.generateFontFaceDeclaration(theme.customFontItalicUrl, 'normal', 'italic'));
    }

    if (theme.customFontBoldItalicUrl) {
      declarations.push(this.generateFontFaceDeclaration(theme.customFontBoldItalicUrl, 'bold', 'italic'));
    }

    return declarations.join('\n');
  };

  generatePresetFontStylesheet = theme => {
    return FONT_FAMILIES[theme.fontFamily].resources.map(resource => `@import '${resource}';`).join('\n');
  };

  generateFontFaceDeclaration = (url, weight, style) => `
    @font-face {
      font-family: ${customFontFamily};
      src: url('${url}') format('woff');
      font-weight: ${weight};
      font-style: ${style};
    }
  `;

  @action
  setBreadcrumbs = (store, stick) => {
    const {
      router: {params},
      platform: {name}
    } = store;
    const {guideSlug, topicSlug, instructionSlug} = params;
    this.breadcrumbs = [];
    const steps = [];
    if (stick) {
      steps.push({
        view: views.explorer,
        params: {},
        text: 'Platform icon'
      });
    }
    steps.push({
      view: views.explorer,
      params: {},
      text: name
    });

    if (guideSlug) {
      steps.push({
        view: views.guide,
        params: {guideSlug},
        text: guideSlug
      });
    }
    if (topicSlug) {
      steps.push({
        view: views.topic,
        params: {guideSlug, topicSlug},
        text: topicSlug
      });
    }
    if (instructionSlug) {
      steps.push({
        view: views.instruction,
        params: {guideSlug, topicSlug, instructionSlug},
        text: instructionSlug
      });
    }

    steps.forEach((step, index) => {
      if (index !== 0) {
        this.breadcrumbs.push(<span className={'arrow'}>></span>);
      }
      this.breadcrumbs.push(
        <Link key={index} store={store} route={step.view} params={step.params}>
          {step.text}
        </Link>
      );
    });
  };

  loginComponent = store => {
    const {platform} = store;
    return platform.isContentProtected ? views.domainLogin.component : views.login.component;
  };

  getCurrentComponent = store => {
    const {
      auth: {user},
      router: {currentRoute},
      platform: {isContentProtected}
    } = store;

    return this.shouldRestrictAccessToRoute({
      user,
      currentRoute,
      protectedContent: isContentProtected
    })
      ? this.loginComponent(store)
      : currentRoute.component;
  };

  shouldRestrictAccessToRoute = ({user, currentRoute, protectedContent}) => {
    const restrictedView = protectedContent && currentRoute.restricted;

    const canViewContent = !!(user && user.canViewContent);

    return restrictedView ? !canViewContent : false;
  };

  addRefetchQuery = ({name, query}) => (this.refetchQueries[name] = query);

  refetchData = language =>
    Object.values(this.refetchQueries).forEach(refetch => {
      refetch({locale: language});
    });

  refetchGuides = sortBy => this.refetchQueries['guides']({sortBy});

  removeRefetchQuery = name => {
    delete this.refetchQueries[name];
  };

  constructor() {
    makeObservable(this);
  }
}

export default AppStore;
