import {Slider} from 'antd';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import inlineSVG from 'inline-svg';
import ReactDOM from 'react-dom';
import {inject, observer} from 'mobx-react';
import Swiper from 'swiper';
import {Navigation, Keyboard, Mousewheel} from 'swiper/modules';

import CampaignQuestion from 'shared/components/CampaignQuestion';
import NextInstructionCard from 'components/NextInstructionCard';
import MaxWidthContainer from 'components/Layout/MaxWidthContainer';
import StepsOverview from 'components/StepsOverview';

import Icon from 'shared/components/Icon';
import {InstructionCoverDesktop as InstructionCover} from 'shared/components/InstructionCover';
import {TextDesktop as Text} from 'shared/components/Text';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import ChecklistOverview from 'shared/components/ChecklistOverview';
import InstructionOutro from 'shared/components/InstructionOutro';

import {INSTRUCTION_TYPE} from 'shared/enums';

import views from 'config/views';

import Step from 'stores/models/step';

import {swiperConfig} from './config';
import messages from './messages';
import {
  SlideWrapper,
  StepCard,
  SwipeableSteps,
  NavigationArrows,
  NavigationButtonNext,
  NavigationButtonPrevious,
  NavigationStepXofX,
  SlideContainer,
  NavigationPrevCardOverlay,
  NavigationNextCardOverlay
} from './styles';

Swiper.use([Navigation, Keyboard, Mousewheel]);

/* How many steps to render before and after the current one */
const OFFSCREEN_STEP_THRESHOLD = 2;

@inject('store')
@observer
class SwipeableStepsComponent extends Component {
  componentDidMount() {
    this.initSwiper();
    this.attachCustomLinkListeners();

    inlineSVG.init({
      svgSelector: 'img.inline-hint-svg',
      initClass: 'js-inlinesvg'
    });
  }

  componentWillReceiveProps(nextProps) {
    const {store} = nextProps;
    const {router, instructionPage} = store;
    if (router.currentRoute.id === views.stepMedia.id) {
      const {guideSlug, instructionSlug, topicSlug, stepId} = router.params;
      const {trackStepView} = instructionPage;
      router.goTo(views.step, {guideSlug, topicSlug, instructionSlug, stepId});
      trackStepView();
    }
  }

  componentWillUnmount() {
    const {store} = this.props;
    const {checklist, router, campaign} = store;

    if (campaign.isSubmitted) {
      campaign.setIsSubmitted(false);
    }

    if (checklist.isChecklistSubmitted && router.currentRoute.id !== views.step.id) {
      checklist.resetChecklist();
    }

    this.swiper.destroy();
    this.removeCustomLinkListeners();
    document.documentElement.classList.remove('js-inlinesvg');
  }

  attachCustomLinkListeners() {
    const allSlideLinks = document.querySelectorAll('.swiper-wrapper a');
    allSlideLinks.forEach(link => {
      link.addEventListener('click', this.checkInternalLink.bind(this));
    });
  }

  removeCustomLinkListeners() {
    const allSlideLinks = document.querySelectorAll('.swiper-wrapper a');
    allSlideLinks.forEach(link => {
      link.removeEventListener('click', this.checkInternalLink.bind(this));
    });
  }

  checkInternalLink(e) {
    const {router} = this.props.store;
    const {href} = e.currentTarget;
    if (href.indexOf('step:') === 0) {
      e.preventDefault();
      const nextStepPosition = parseInt(href.split(':')[1], 0);
      router.goTo(views.step, {
        guideSlug: router.params.guideSlug,
        topicSlug: router.params.topicSlug,
        instructionSlug: router.params.instructionSlug,
        stepId: nextStepPosition
      });
    }
    if (href.indexOf('instruction:') === 0) {
      e.preventDefault();
      const nextInstructionSlug = href.split(':')[1];
      router.goTo(views.instruction, {
        guideSlug: router.params.guideSlug,
        topicSlug: router.params.topicSlug,
        instructionSlug: nextInstructionSlug
      });
    }
  }

  initSwiper() {
    const {store} = this.props;
    const {router, instructionPage: page} = store;
    const {setLastVisitedStep, currentInstruction, trackStepView, trackInstructionView} = page;
    this.swiper = new Swiper(ReactDOM.findDOMNode(this), swiperConfig);
    this.selectSlide();

    if (router.params.stepId) {
      trackStepView();
    }

    this.swiper.on('slideChange', () => {
      router.goTo(views.step, {
        guideSlug: router.params.guideSlug,
        topicSlug: router.params.topicSlug,
        instructionSlug: router.params.instructionSlug,
        stepId: this.swiper.realIndex
      });

      setLastVisitedStep(router.params.stepId, this.swiper?.slides?.length);

      if (router.params.stepId) {
        trackStepView();
      } else if (currentInstruction) {
        trackInstructionView();
      }
    });

    this.swiper.on('slideChangeTransitionStart', () => {
      // check if this is touch interaction
      if (!this.swiper.touches.diff) {
        return;
      }

      const eventsMapKey = this.swiper.touches.diff > 0 ? 'prevStepBySwipe' : 'nextStepBySwipe';
      this.trackStepChange(eventsMapKey);

      // reset the touch diff
      this.swiper.touches.diff = 0;
    });
  }

  selectSlide() {
    const {store} = this.props;
    const {router, instructionPage} = store;
    const {setLastVisitedStep} = instructionPage;
    if (router.params.stepId) {
      this.swiper.slideTo(router.params.stepId);
      setLastVisitedStep(router.params.stepId, this.swiper.slides.length);
    } else {
      this.swiper.slideTo(0);
      setLastVisitedStep(0);
    }
  }

  trackStepChange = eventsMapKey => {
    const {
      instructionPage: {trackStepChange}
    } = this.props.store;
    trackStepChange(eventsMapKey);
  };

  isOffscreenStep = index => {
    const {store} = this.props;
    const {instructionPage} = store;
    const {activeStepNumber} = instructionPage;

    return Math.abs(activeStepNumber - index) > OFFSCREEN_STEP_THRESHOLD;
  };

  componentDidUpdate() {
    const {
      store: {
        checklist: {isChecklistSubmitted},
        campaign: {isSubmitted: isCampaignSubmitted}
      }
    } = this.props;

    if (isChecklistSubmitted || isCampaignSubmitted) {
      this.swiper.detachEvents();
      return;
    }

    this.selectSlide();
  }

  getLastOutroInstructionText = (
    isChecklist,
    showCampaignQuestion,
    formatMessage,
    isChecklistSubmitted,
    isCampaignSubmitted
  ) => {
    if (!isChecklist && !showCampaignQuestion) {
      return {title: '', subtitle: ''};
    }

    let lastOutroTitle = '';
    let lastOutroSubtitle = '';
    if (isChecklist) {
      lastOutroTitle = formatMessage(isChecklistSubmitted ? messages.title : messages.titleNotSubmitted);
      lastOutroSubtitle = formatMessage(isChecklistSubmitted ? messages.subtitle : messages.subtitleNotSubmitted);
    } else {
      lastOutroTitle = formatMessage(
        isCampaignSubmitted ? messages.sign_off_submitted_title : messages.sign_off_not_submitted_title
      );
      lastOutroSubtitle = formatMessage(
        isCampaignSubmitted ? messages.sign_off_submitted_subtitle : messages.sign_off_not_submitted_subtitle
      );
    }

    return {title: lastOutroTitle, subtitle: lastOutroSubtitle};
  };

  render() {
    const {
      store,
      layout,
      steps,
      showCampaignQuestion,
      campaign,
      instructionId,
      imageUrl,
      stepsNumber,
      nextInstruction,
      relatedInstructions,
      nextTopic,
      guideTitle,
      instructionTitle,
      metadata,
      intl
    } = this.props;
    const {
      router: {params},
      instructionPage,
      platform: {hasChecklistsEnabled},
      checklist: {isChecklistSubmitted},
      campaign: {isSubmitted: isCampaignSubmitted}
    } = store;
    const {formatMessage} = intl;
    const {activeStepNumber, currentInstruction} = instructionPage;
    const {guideSlug, topicSlug} = params;
    const hasNextInstruction = !!nextInstruction;
    const hasNextTopic = !hasNextInstruction && !!nextTopic && nextTopic.firstInstruction;

    const slideCount = steps.length + 4;

    const containerStyle = {
      height: layout.containerHeight,
      marginTop: layout.extraVerticalSpace,
      width: `${slideCount * 100}%`
    };

    const overlayStyle = {
      height: layout.overlayHeight,
      width: layout.overlayWidth
    };

    const slideStyle = {
      height: layout.slideHeight,
      padding: `0 ${layout.cardSpacing / 2}px`,
      width: layout.slideWidth
    };

    const imageStyle = {
      height: layout.imageHeight,
      width: layout.imageWidth
    };

    // "sizes" attributes to pick a proper "srcset" option:
    const coverImageSizes = `${layout.imageWidth * 2}px`;
    const hintImageSizes = `${layout.imageWidth / 2 - 32}px`;
    const imageSizes = `${layout.imageWidth}px`;
    const relatedImageSizes = `calc((${layout.imageWidth}px - 6rem) / 2)`;

    const showActiveStep = activeStepNumber > 0 && activeStepNumber <= steps.length;

    const isChecklist = currentInstruction.type === INSTRUCTION_TYPE.CHECKLIST;
    const typeXofX_message = isChecklist ? messages.checkXofX : messages.stepXofX;
    const {title, subtitle} = this.getLastOutroInstructionText(
      isChecklist,
      showCampaignQuestion,
      formatMessage,
      isChecklistSubmitted,
      isCampaignSubmitted
    );

    return (
      <SwipeableSteps>
        <SlideWrapper style={containerStyle}>
          <SlideContainer style={slideStyle}>
            <InstructionCover
              imageUrl={imageUrl}
              imageSizes={coverImageSizes}
              guideTitle={guideTitle}
              instructionTitle={instructionTitle}
              metadata={metadata}
              aspectRatio={layout.ratio}
              isChecklist={isChecklist}
            />
          </SlideContainer>
          {steps.map((step, index) => (
            <SlideContainer key={index} style={slideStyle}>
              {!this.isOffscreenStep(index) && (
                <StepCard
                  isCheck={isChecklist}
                  hintImageSizes={hintImageSizes}
                  imageSizes={imageSizes}
                  step={new Step(step)}
                  orientation={layout.orientation}
                  stepIndex={index}
                  checklistTemplate={currentInstruction?.checklistTemplate}
                />
              )}
            </SlideContainer>
          ))}
          {showCampaignQuestion && !isChecklist && (
            <SlideContainer style={slideStyle}>
              <CampaignQuestion
                instructionId={instructionId}
                nextButton={this.nextButtonRef}
                device="desktop"
                followUpQuestion={campaign.followupQuestion}
                form={campaign.form}
                isSubmissionFailureModalOpen={campaign.isSubmissionFailureModalOpen}
                lastDateOfAnswerForUser={campaign.lastDateOfAnswerForUser}
                onAnswer={() => {
                  this.setState({forceRender: Date.now()});
                  campaign.answerQuestion();
                }}
                onFollowUpAnswer={() => {
                  this.setState({forceRender: Date.now()});
                  campaign.answerFollowup();
                }}
                onSubmissionFailureModalClose={() => campaign.setIsSubmissionFailureModalOpen(false)}
                onSubmit={campaign.submitSignOff}
                question={campaign.activeQuestion}
                SliderComponent={Slider}
                step={campaign.step}
              />
            </SlideContainer>
          )}
          {isChecklist && hasChecklistsEnabled && (
            <SlideContainer style={slideStyle}>
              <ChecklistOverview
                nextButton={this.nextButtonRef}
                StepsOverviewComponent={StepsOverview}
                isDesktop={true}
              />
            </SlideContainer>
          )}
          {hasNextInstruction && (
            <SlideContainer style={slideStyle}>
              <NextInstructionCard
                aspectRatio={layout.ratio}
                nextInstruction={nextInstruction}
                relatedInstructions={relatedInstructions}
                guideSlug={guideSlug}
                topicSlug={topicSlug}
                relatedImageSizes={relatedImageSizes}
                imageSizes={coverImageSizes}
                imageStyle={imageStyle}
                className={layout.orientation}
                guideTitle={guideTitle}
              />
            </SlideContainer>
          )}
          {hasNextTopic && (
            <SlideContainer style={slideStyle}>
              <NextInstructionCard
                nextInstruction={nextTopic.firstInstruction}
                guideSlug={guideSlug}
                topicSlug={nextTopic.slug}
                relatedImageSizes={relatedImageSizes}
                imageSizes={coverImageSizes}
                imageStyle={imageStyle}
                className={layout.orientation}
                guideTitle={guideTitle}
              />
            </SlideContainer>
          )}
          {!hasNextInstruction && !hasNextTopic && (isChecklist || showCampaignQuestion) && (
            <SlideContainer style={slideStyle}>
              <InstructionOutro isDesktop={true} title={title} subtitle={subtitle} />
            </SlideContainer>
          )}
        </SlideWrapper>
        <NavigationPrevCardOverlay className={'swiper-button-prev'} style={overlayStyle} />
        <NavigationNextCardOverlay className={'swiper-button-next'} style={overlayStyle} />
        <MaxWidthContainer>
          <NavigationArrows>
            <NavigationButtonPrevious
              disabled={isChecklistSubmitted || isCampaignSubmitted}
              onClick={() => this.trackStepChange('prevStepByButton')}
            >
              <Icon name={'arrow-left'} size={32} />
            </NavigationButtonPrevious>
            <NavigationStepXofX>
              <Text>
                {showActiveStep && (
                  <FormattedMessage
                    {...typeXofX_message}
                    values={{
                      total: <strong>{stepsNumber}</strong>,
                      current: <strong>{activeStepNumber}</strong>
                    }}
                  />
                )}
                {showCampaignQuestion && activeStepNumber === steps.length + 1 && (
                  <FormattedMessage {...messages.campaignSignOff} />
                )}
              </Text>
            </NavigationStepXofX>
            <NavigationButtonNext
              ref={el => (this.nextButtonRef = el)}
              onClick={() => this.trackStepChange('nextStepByButton')}
            >
              <Icon name={'arrow-right'} size={32} />
            </NavigationButtonNext>
          </NavigationArrows>
        </MaxWidthContainer>
      </SwipeableSteps>
    );
  }
}

export default injectIntl(SwipeableStepsComponent);
