import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {defineMessages} from 'react-intl';
import views from 'config/views';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import Link from 'shared/components/Link';

//styled-components
import {PlatformIconAndName, IconWrapper, IconHolder, BasicLinkBoth, Name} from './styles';

// components
import PlatformIcon from './PlatformIcon';

const messages = defineMessages({
  menuItem_explore: {
    id: 'menuItem_explore',
    defaultMessage: 'Explore'
  }
});

@inject('store')
class PlatformIconAndNameComponent extends Component {
  render() {
    const {store} = this.props;
    const {
      app: {
        theme: {name: themeName, faviconUrl}
      },
      explorerPage,
      guidePage
    } = store;
    const {toggleSearchInputVisibility} = explorerPage;
    const {setBackByPlatformLink} = guidePage;
    const isExplorerPage = store.router.currentRoute.id === 'explorer';

    const {stick} = this.props;
    const displayName =
      themeName && themeName !== 'default' ? themeName : <FormattedMessage {...messages.menuItem_explore} />;

    const LinkTag = isExplorerPage ? BasicLinkBoth : BasicLinkBoth.withComponent(Link);

    const LinkTagProps = isExplorerPage
      ? {
          onClick: () => {
            toggleSearchInputVisibility(false);
            setBackByPlatformLink(true);
          }
        }
      : {
          route: views.explorer,
          store,
          onClickReaction: () => {
            toggleSearchInputVisibility(false);
            setBackByPlatformLink(true);
          }
        };

    return (
      <PlatformIconAndName>
        <LinkTag {...LinkTagProps}>
          <IconWrapper stick={stick} iconAvailable={faviconUrl}>
            <IconHolder>
              <PlatformIcon displayName={displayName} />
            </IconHolder>
          </IconWrapper>
          <Name>{displayName}</Name>
        </LinkTag>
      </PlatformIconAndName>
    );
  }
}

export default PlatformIconAndNameComponent;
