import React, {Component} from 'react';

import Icon from 'shared/components/Icon';
import {TextDesktop as Text} from 'shared/components/Text';
import Link from 'shared/components/Link';

//styled-components
import {ContextMenuLink} from './styles';

class ContextMenuLinkComponent extends Component {
  render() {
    const {children, iconName, tag = Link} = this.props;
    const ContextMenuTag = ContextMenuLink.withComponent(tag);
    return (
      <ContextMenuTag {...this.props}>
        <Icon name={iconName} size={24} />
        <Text size={'small'}>{children}</Text>
      </ContextMenuTag>
    );
  }
}

export default ContextMenuLinkComponent;
