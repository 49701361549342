import {defineMessages} from 'react-intl';

const messages = defineMessages({
  pageTitle_stepsOverview: {
    id: 'pageTitle_stepsOverview',
    defaultMessage: 'Steps overview: {title}'
  },
  pageTitle_action_backToCompleteGuide: {
    id: 'pageTitle_action_backToCompleteGuide',
    defaultMessage: 'back to complete guide'
  },
  pageTitle_action_back: {
    id: 'pageTitle_action_back',
    defaultMessage: 'back'
  },
  pageTitle_info_noStepsInInstruction: {
    id: 'pageTitle_info_noStepsInInstruction',
    defaultMessage: 'there are no steps in this instruction'
  },
  x_steps: {
    id: 'x_steps',
    defaultMessage: '{stepCount, number} {stepCount, plural, one {step} other {steps}}'
  },
  x_checks: {
    id: 'x_checks',
    defaultMessage: '{checkCount, number} {checkCount, plural, one {check} other {checks}}'
  },
  pageTitle_checksOverview: {
    id: 'pageTitle_checksOverview',
    defaultMessage: 'Checks overview: {title}'
  }
});

export default messages;
