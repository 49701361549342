import styled from 'styled-components';

export const PlatformIconAndName = styled.div``;

export const IconHolder = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  margin-top: 12px;
  overflow: hidden;

  img {
    display: block;
  }
  svg {
    width: 24px;
    height: 24px;
    display: block;
    float: left;
    fill: ${p => p.theme.menubarTextColor};
    path {
      fill: ${p => p.theme.menubarTextColor};
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  width: ${p => (p.stick && p.iconAvailable ? '3rem' : 0)};
  height: 3rem;
  overflow: hidden;
  transition: width 0.1s;
  margin-left: -1rem;
  margin-right: 0rem;
`;

export const Name = styled.span`
  height: 3rem;
  line-height: inherit;
`;

export const BasicLinkBoth = styled.a`
  display: block;
  padding: 0 1rem;
  height: 3rem;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    color: inherit;
  }
  > * {
    float: left;
  }
`;
