import styled from 'styled-components';
import {borderRadius, zIndex} from '../../styles/vars';

export const EditDraftGuide = styled.a`
  position: fixed;
  z-index: ${zIndex.guidePreviewNotification};
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0.5rem;
  border-radius: 0 0 ${borderRadius.default}px ${borderRadius.default}px;
  background: #cc0000;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  &:hover {
    color: #ffffff;
  }
`;
