import get from 'lodash/get';
import map from 'lodash/map';
import {toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {SHOULD_SHOW_INSTRUCTIONS_ON_SIMPLE_SEARCH} from 'shared/constants';
import {injectIntl} from 'react-intl';

//helpers
import {normalizeSimpleSearchResult} from 'shared/utils/search-result-utils';

//queries
import {SimpleSearch} from 'api/simple-search/queries';
import {simpleSearchOptions} from 'api/simple-search/query-options';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';
import TagPicker from 'shared/components/TagPicker';
import BaseSearchBarDropdown from './BaseSearchBarDropdown';

//messages
import messages from './messages';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

/**
 * Fetches simple search results from the API and feeds them to the
 * <BaseSearchBarDropdown /> component.
 */
@inject('store')
@graphql(SimpleSearch, simpleSearchOptions)
@observer
class SimpleSearchBarDropdownComponent extends Component {
  static propTypes = {
    searchTerm: PropTypes.string,
    skipSearch: PropTypes.bool
  };

  componentDidMount() {
    const {
      store: {
        explorerPage: {setTranslations}
      },
      intl: {formatMessage}
    } = this.props;

    const translations = mapValues(pick(messages, ['loading']), message => formatMessage(message));

    setTranslations(translations);
  }

  render() {
    const {onModeToggleClick, simpleSearch, store} = this.props;
    const {explorerPage} = store;
    const {allTags, isTagPickerDialogOpen, selectedTags, setIsTagPickerDialogOpen, setSelectedTags} = explorerPage;

    const guides = map(get(simpleSearch, 'simpleSearch.results.guides'), guide =>
      normalizeSimpleSearchResult(guide, 'guides')
    );

    const instructions = SHOULD_SHOW_INSTRUCTIONS_ON_SIMPLE_SEARCH
      ? map(get(simpleSearch, 'simpleSearch.results.instructions'), instruction =>
          normalizeSimpleSearchResult(instruction, 'instructions')
        )
      : [];

    const hasError = !!get(simpleSearch, 'error');

    return (
      <span>
        <BaseSearchBarDropdown
          guideResults={guides}
          hasError={hasError}
          instructionResults={instructions}
          introSectionContent={
            <TagPicker
              allTags={allTags}
              isDialogOpen={isTagPickerDialogOpen}
              onChange={setSelectedTags}
              onDialogClose={() => setIsTagPickerDialogOpen(false)}
              onDialogOpen={() => setIsTagPickerDialogOpen(true)}
              value={toJS(selectedTags)}
            />
          }
          introSectionTitle={<FormattedMessage {...messages.searchPage_filters} />}
          isLoading={get(simpleSearch, messages.loading)}
          isSimpleSearchMode
          maxResults={5}
          mode="simple"
          onModeToggleClick={onModeToggleClick}
        />
      </span>
    );
  }
}

export default injectIntl(SimpleSearchBarDropdownComponent);
