import styled from 'styled-components';
import $Tag from 'shared/components/Tag';

export const SearchBarWrapper = styled.div`
  position: relative;
  display: flex;
  ${p =>
    p.searchBarVisible &&
    `
    flex: 1;
    min-width: 350px;
  `}
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  padding-right: 3rem;
  input {
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background: none;
    border: none;
    line-height: inherit;
    &::placeholder {
      color: ${p => p.theme.menubarSearchPlaceholder};
    }
    &:focus {
      outline: none;
    }
  }
  button {
    position: absolute;
    right: 8px;
    bottom: 2px;
  }
`;

export const StyledInput = styled.input`
  &::-webkit-search-cancel-button {
    display: none;
  }
`;

export const SearchResetButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: inherit;
  height: 100%;
  float: right;
  padding-left: 1rem;
  cursor: pointer;
`;

export const Tag = styled($Tag)`
  margin-right: 16px;
`;
