import gql from 'graphql-tag';

export const GuidesList = gql`
  query pagedGuides($offset: Int, $limit: Int, $locale: Locale!, $sortBy: SortByOption) {
    pagedGuides(offset: $offset, limit: $limit, locale: $locale, sortBy: $sortBy) {
      guides {
        id
        title
        imageUrl
        media {
          metadata
        }
        slug
      }
      nextOffset
    }
  }
`;

export const GuideOverview = gql`
  query guide($slug: String!, $locale: Locale!) {
    guide(slug: $slug, locale: $locale) {
      id
      title
      imageUrl
      totalPublishedInstructionsNumber
      slug
      topics {
        id
        slug
        title
        position
        guideId
        visible
        collapsible
        instructions(publishStatus: PUBLISHED) {
          slug
          title
          imageUrl
          stepsNumber
          type
          media {
            metadata
          }
        }
      }
    }
  }
`;

export const GuideTitle = gql`
  query guide($slug: String!, $locale: Locale!) {
    guide(slug: $slug, locale: $locale) {
      id
      title
      publishedVersion {
        _id
      }
    }
  }
`;

export const GuideStatus = gql`
  query guide($slug: String!, $locale: Locale!) {
    guide(slug: $slug, locale: $locale) {
      id
      publishStatus
    }
  }
`;
