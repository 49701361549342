import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

import LanguagePicker from 'components/LanguagePicker';
import OverlayMenuContainer from 'components/Layout/OverlayMenuContainer';
import Icon from 'shared/components/Icon';

import views from 'config/views';

import {posthogCapture} from 'shared/utils/posthog-utils';
import {posthogEvents} from 'shared/posthog-events';

import MenuItem from './MenuItem';
import SearchBar from './SearchBar';
import PlatformIconAndName from './PlatformIconAndName';

import {BarWrapper, Left, Right, StyledOption} from './styles';
import messages from './messages';

@inject('store')
@observer
class MenuBarComponent extends Component {
  render() {
    const {
      store,
      stick,
      intl: {formatMessage}
    } = this.props;
    const {auth, explorerPage, platform, router} = store;
    const {goToSearchPage, searchTerm, selectedTags, shouldSearchInputBeVisible, toggleSearchInputVisibility} =
      explorerPage;
    const {logout, showAuthButtons, user} = auth;
    const isExplorerPage = router.currentRoute.id === 'explorer';

    const showLanguageSelector = platform.availableLanguages.length > 1;
    const showSearchBar = platform.isContentProtected ? !!user : true;

    return (
      <BarWrapper stick={stick}>
        <Left>
          <PlatformIconAndName stick={stick} isExplorerPage={isExplorerPage} />
        </Left>
        <Right>
          {showSearchBar && (
            <SearchBar
              menuIcon={
                <MenuItem
                  onClick={() => {
                    if ((searchTerm || selectedTags.length) && shouldSearchInputBeVisible) {
                      goToSearchPage();
                    } else {
                      toggleSearchInputVisibility();
                    }
                  }}
                  active={shouldSearchInputBeVisible}
                >
                  <Icon name={'magnifier'} />
                </MenuItem>
              }
            />
          )}
          {showLanguageSelector && (
            <MenuItem
              content={
                <OverlayMenuContainer>
                  <LanguagePicker />
                </OverlayMenuContainer>
              }
            >
              <Icon name={'globe'} />
            </MenuItem>
          )}
          <MenuItem
            content={
              <OverlayMenuContainer>
                <MenuItem
                  onClick={() => {
                    router.goTo(views.privacy, {});
                  }}
                >
                  <StyledOption>{formatMessage(messages.privacy)}</StyledOption>
                </MenuItem>
                {showAuthButtons && user && user.email && (
                  <MenuItem
                    onClick={() => {
                      posthogCapture(posthogEvents.selectPassword);
                      router.goTo(views.changePassword, {});
                    }}
                  >
                    <StyledOption>{formatMessage(messages.password)}</StyledOption>
                  </MenuItem>
                )}
                {showAuthButtons && (
                  <MenuItem onClick={logout}>
                    <StyledOption>{formatMessage(messages.signOut)}</StyledOption>
                  </MenuItem>
                )}
              </OverlayMenuContainer>
            }
          >
            <Icon name={'hamburger'} data-testid="hamburger-menu" />
          </MenuItem>
        </Right>
      </BarWrapper>
    );
  }
}

export default injectIntl(MenuBarComponent);
